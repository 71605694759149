import {
  Button,
  DialogActions,
  DialogBody,
  DialogContent,
  Combobox,
  Input,
  InputOnChangeData,
  Label,
  makeStyles,
  Textarea,
  Checkbox,
  Spinner,
  CheckboxOnChangeData,
  ComboboxOpenChangeData,
  Image,
  Text,
  Option,
  Popover,
  PopoverTrigger,
  PopoverSurface
} from "@fluentui/react-components";
import * as React from "react";
import { ThumbLike24Filled, ThumbDislike24Filled } from '@fluentui/react-icons';
import _ from 'lodash';
import { TCDataTypes, TCInputType, TCInputTypeUtils } from "../../services/TCInputTypeUtils";
import { useCreateProblem } from "../../hooks/useCreateProblem";
import { useOpenAI } from '../../hooks/useOpenAI';
import { dialog, HostClientType } from "@microsoft/teams-js";
import * as microsoftTeams from '@microsoft/teams-js';
import { ICreateData, CreateData, IGetDeclarativeStyleSheetsRequestData, StyleSheetType, IGetInitialLovRequestData, 
  IValidateLOVSelectionValues, ValidateLOVSelectionValues } from "../common/TcSOATypes";
import { logger } from "../../Logger";
import { UseMutationTypeQuery } from "../../types/ApiReturnType";
import { OpenAIInput, OperationName, FeedbackInput, AllowedChangeType } from "../../types/CommonTypes";
import { Constants } from "../common/Constants";
import ProblemItemPicker from "./ProblemItemPicker";
import { AttachedItem, IAttachedItem } from "../../types/ProblemType";
import { TeamsFxContext } from '../Context';
import { captureImage } from '../../services/cameraHelper';
import { ITCLOVDataType, TCLOVDataType, TCLOVTypeUtils } from "../../services/TcLovTypeUtils";
import { useGetInitialLov } from "../../hooks/useGetInitialLov";
import { useValidateLovValueSelections } from "../../hooks/useValidateLovValueSelections";
import LovRenderer from "../common/LovRenderer";
import { useGetDeclarativeStyleSheet } from "../../hooks/useGetDeclarativeStyleSheet";
import { useGetCreatableChangeTypes } from "../../hooks/useGetCreatableChangeTypes";
import { AnalyticsManager } from "../../services/analyticsUtility";
import AnalyticsConstants from "../common/AnalyticsConstants.json";
import { useParams } from "react-router-dom";
import { ProblemDetailsUtils } from "./ProblemDetailsUtils";
import { RequestUtils } from "../../services/RequestUtils";
import { formatTemplateString } from "../../services/utility";
import DateTime from '../common/widget/DateTime';
import { dateTimeToTcUTCDateTime } from "../../services/utility";

const useStyles = makeStyles({
  textarea: {
    height: "80vh",
  }
});
type ProblemDetailsParams = {
  internalName: string;
  displayName: string;
};
const ProblemDetails: React.FunctionComponent = () => {

  // Logging create problem report panel visit in analytics
  React.useEffect( () => {
    async function startProcessing() {
      if (teamsContext.configuration) {
        const analyticsManager = AnalyticsManager.getInstance();
        analyticsManager.logPlace(AnalyticsConstants.panelCreateObject);
      }
    }
    setSelectedDate(new Date());
    startProcessing();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styles: any = useStyles();
  const problemReport: any = useParams<ProblemDetailsParams>();

  // Use State Parameters
  const [tcInputFields, setInputFields] = React.useState<TCInputType[]>([]);
  const [tcLovFields, setLovFields] = React.useState<any>();
  const [showhandleTellMeForm, setShowForm] = React.useState<boolean>(false);
  const [summarizeInProgress, setSummarizeInProgress] = React.useState<boolean>(false);
  const [summarizeContent, setSummaryContent] = React.useState<string>('');
  const [isCreatingPR, setCreatingPR] = React.useState<boolean>(false);
  const [attachedItem, setAttachedItem] = React.useState<IAttachedItem>();
  const [imageUrl, setImageUrl] = React.useState<string>('');
  const [imageRef, setImageRef] = React.useState<HTMLImageElement>();
  const [capturedImageFile, setCapturedImageFile] = React.useState<File>();
  const [capturedImageFiles, setCapturedImageFiles] = React.useState<File[]>();
  const modifiedProps = React.useRef<string[]>([]);
  const teamsContext = React.useContext(TeamsFxContext);
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
  const [timePickerError, setTimePickerError] = React.useState<boolean>(false);
  const i18n = teamsContext.i18n;

  const initialRef: any = null;
  const hiddenFileInput = React.useRef<HTMLInputElement>(initialRef);

  // API Mutations
  const createProblem: UseMutationTypeQuery<FormData, any> = useCreateProblem();
  const openApi: UseMutationTypeQuery<OpenAIInput, any> = useOpenAI();
  const getStyleSheet: UseMutationTypeQuery<IGetDeclarativeStyleSheetsRequestData, any> = useGetDeclarativeStyleSheet();
  const getIntialLov: UseMutationTypeQuery<IGetInitialLovRequestData, any> = useGetInitialLov();
  const validateLOVSelectionAPI: UseMutationTypeQuery<ValidateLOVSelectionValues, any> = useValidateLovValueSelections();
  const [isPositiveFeedbackSelected, setIsPositiveFeedbackSelected] = React.useState<boolean>(false);
  const [isNegativeFeedbackSelected, setIsNegativeFeedbackSelected] = React.useState<boolean>(false);
  const openAIPropList = React.useRef<string[]>([]);
  const [summarizeSessionId, setSummarizeSessionId] = React.useState<string>('');
  const [isFeedbackDisabled, setIsFeedbackDisabled] = React.useState<boolean>(true);
  const [showPopover, setShowPopover] = React.useState<boolean>(false);
  const [showValidationMessage, setShowValidationMessage] = React.useState<boolean>(false);
  const creatableChangeTypesApi = useGetCreatableChangeTypes(problemReport.internalName);
  const [selectedTypeDisplayName, setSelectedTypeDisplayName] = React.useState("");
  const [selectedTypeName, setSelectedTypeName] = React.useState("");
  const { creatableChangeTypesOutput } = creatableChangeTypesApi.allowedChangeTypes || {};
  const types: AllowedChangeType[] = React.useMemo(() => creatableChangeTypesOutput?.[0]?.allowedChangeTypes || [], [creatableChangeTypesOutput]);
  const keyMap = React.useMemo(() => {
    return new Map(types.map((type: AllowedChangeType) => [type.typeName, type.typeDisplayName]));
  }, [types]);

  // Initalize teams app and notify success of loading
  React.useEffect(() => {
    (async () => {
      await microsoftTeams.app.initialize();
      microsoftTeams.app.notifySuccess();
    })();
  }, []);

  // Check if keyMap has elements and set the default values for problem report type lov
  React.useEffect(() => {
    if (keyMap && keyMap.size > 0) {
      const firstEntry = keyMap.entries().next().value;
      if (firstEntry && firstEntry[0] && firstEntry[1]) {
        setSelectedTypeName(firstEntry[0]);
        setSelectedTypeDisplayName(firstEntry[1]);
      }
    }
  }, [keyMap]);

  // Use Effect Hook
  React.useEffect(() => {
    async function startProcessing() {
      const prType: string = selectedTypeName;
      if (prType) {
        const requestData: IGetDeclarativeStyleSheetsRequestData = TCInputTypeUtils.getXRTRequestData(prType, StyleSheetType.CREATE, Constants.dummyBusinessObject);
        if (requestData) {
          const xrtResponse = await getStyleSheet.mutateAsync(requestData);
          if (xrtResponse) {
            const tcInputTypes: TCInputType[] = TCInputTypeUtils.extractTCInputTypes(xrtResponse.viewModel, xrtResponse.modelObject);
            setInputFields(tcInputTypes);
            const lovTypeMap: any = TCLOVTypeUtils.populateTCLOVDataType(tcInputTypes);
            setLovFields(lovTypeMap);
            const listOfProps = tcInputTypes
              .filter((prop) => prop.value.length === 0 && !prop.hasLOV)
              .map((prop) => prop.propertyName);
            openAIPropList.current = listOfProps;
          }
        }
      }
    }
    startProcessing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTypeName]);

  //Scroll Image into the current view to show the result to the user
  React.useEffect(() => {
    imageRef?.scrollIntoView({ behavior: 'smooth' });
  }, [imageUrl, imageRef]);

  // Input Field Type Handlers/Updaters

  const handleLovValueChange = (typeName: string) => {
    setSelectedTypeDisplayName(typeName);
    const keyValueArray: [string, string][] = Array.from(keyMap.entries());
    for (const [key, value] of keyValueArray) {
      if (value === typeName) {
        setSelectedTypeName(key);
      }
    }
  };

  const handleStringPropertyChange = (ev: React.ChangeEvent, data: InputOnChangeData) => {
    logger.logTrace(`Entered ${handleStringPropertyChange.name}`);
    const updatedInputs = tcInputFields.map((input) => {
      if (input.propertyName === ev.target.id) {
        return {
          ...input,
          value: data.value,
        };
      }
      return input;
    });

    setInputFields(updatedInputs);
    logger.logTrace(`Exit ${handleStringPropertyChange.name}`);
  };

  const handleBooleanPropertyChange = (ev: React.ChangeEvent, data: CheckboxOnChangeData) => {
    logger.logTrace(`Entered ${handleBooleanPropertyChange.name}`);
    const updatedInputs = tcInputFields.map((input) => {
      if (input.propertyName === ev.target.id) {
        return {
          ...input,                       // Copy the old fields
          value: data.checked.toString(), // But override this one
        };
      }
      return input;
    });

    setInputFields(updatedInputs);
    logger.logTrace(`Exit ${handleBooleanPropertyChange.name}`);
  };

  const handleTimePickerError = (errorExists:boolean) => {
    setTimePickerError(errorExists);
  };

  const handleDatePropertyChange = (date: Date | null | undefined, id:string) => {
    logger.logTrace(`Entered ${handleDatePropertyChange.name}`);
    if (date) {
      setSelectedDate(date);
      const utcTime= dateTimeToTcUTCDateTime(date);
      const updatedInputs = tcInputFields.map((input) => {
        if (input.propertyName === id) {
          return {
            ...input,
            value: utcTime,
          };
        }
        return input;
      });
      setInputFields(updatedInputs);
    }
    logger.logTrace(`Exit ${handleDatePropertyChange.name}`);
  };

  const handleProblemItem = (value?: AttachedItem) => {
    if (value) {
      setAttachedItem(value);
    }
  };

  // Method for calling getInitialLovValues on search string
  const onInputUpdate = (prop: TCInputType, event: any) => {
    logger.logTrace(`Entered ${onInputUpdate.name}`);
    prop.value = event.target.value.trim();
    callGetInitialLOVValues(prop.propertyName, event.target.value.trim());
    const tcLovType: TCLOVDataType | undefined = tcLovFields[prop.propertyName];
    if (tcLovType) {
      tcLovType.isValueSelected = false;
    }
    logger.logTrace(`Exit ${onInputUpdate.name}`);
  };

  // Method for calling getInitialLovValues
  const callGetInitialLOVValues = async (elementId: string, filterString: string) => {
    logger.logTrace(`Entered ${callGetInitialLOVValues.name}`);
    const tcLovType: TCLOVDataType | undefined = tcLovFields[elementId];
    if (tcLovType) {
      tcLovType.filterString = filterString;
      const requestData: IGetInitialLovRequestData = TCLOVTypeUtils.prepareGetInitialLovRequestData(tcLovType, tcInputFields, OperationName.CREATE, modifiedProps.current);
      if (requestData) {
        const response: any = await getIntialLov.mutateAsync(requestData);
        if (response && response.lovValues) {
          const dirtyProps: string[] = TCLOVTypeUtils.extractLovValues(response, tcLovType);
          if (dirtyProps.length > 0) {
            modifiedProps.current = _.union(dirtyProps, modifiedProps.current);
          }
        }
      }
    }
    logger.logTrace(`Exit ${callGetInitialLOVValues.name}`);
  };

  const handleChangeBox = async (elementId: string, data: ComboboxOpenChangeData, event: any) => {
    logger.logTrace(`Entered ${handleChangeBox.name}`);
    const tcLovType: TCLOVDataType | undefined = tcLovFields[elementId];
    if (tcLovType) {
      if (data && data.open) {
        tcLovType.lovDataValue = undefined;
        callGetInitialLOVValues(elementId, "");
      } else if (!data.open) {
        if (!tcLovType.isValueSelected) {
          const updatedInputs = tcInputFields.map((input) => {
            if (input.propertyName === event.target.id) {
              return {
                ...input,
                value: tcLovType.selectedValue,
                dbValues: TCLOVTypeUtils.getSelectedLovValue(tcLovType.selectedValue, tcLovType),
              };
            }
            return input;
          });
          setInputFields(updatedInputs);
        }
        tcLovType.isValueSelected = tcLovType.selectedValue ? true : false;
      }
    }
    logger.logTrace(`Exit ${handleChangeBox.name}`);
  };

  const clearDependentLOVFields = (dependentProp: string) => {
    logger.logTrace(`Entered ${clearDependentLOVFields.name}`);
    const lovType: ITCLOVDataType = TCLOVTypeUtils.findLOVUsingInternalName(dependentProp, tcLovFields);
    if (lovType) {
      lovType.isDependentLov = true;
      lovType.selectedValue = '';
      lovType.lovDataValue = undefined;
    }
    logger.logTrace(`Entered ${clearDependentLOVFields.name}`);
  };

  const handleDropDownValueChange = async (optionValue: string | undefined, tcLovType: TCLOVDataType) => {
    logger.logTrace(`Entered ${handleDropDownValueChange.name}`);
    if (optionValue) {
      const validateLOVRequest: IValidateLOVSelectionValues = TCLOVTypeUtils.prepareValidateLOVSelectionRequestData(tcLovType, tcInputFields, OperationName.EDIT, modifiedProps.current);
      if (validateLOVRequest) {
        const validateLOVResponse: any = await validateLOVSelectionAPI.mutateAsync(validateLOVRequest);
        if (validateLOVResponse) {
          const updateLovTypeList: any = {};
          if (validateLOVResponse.dependentPropNames && validateLOVResponse.dependentPropNames.length > 0) {
            const dependentProps: string[] = validateLOVResponse.dependentPropNames;
            for (const depProp of dependentProps) {
              clearDependentLOVFields(depProp);
              updateLovTypeList[depProp] = { key: depProp, dbValue: "", value: "" };
            }
            tcLovType.isValueSelected = true;
          }
          if (validateLOVResponse.propHasValidValues) {
            tcLovType.selectedValue = optionValue;
            updateLovTypeList[tcLovType.internalLovName] = { key: tcLovType.internalLovName, dbValue: TCLOVTypeUtils.getSelectedLovValue(optionValue, tcLovType), value: tcLovType.selectedValue.toString() }
            tcLovType.isValueSelected = true;
          }
          const updatedInputs: TCInputType[] = tcInputFields.map((input) => {
            const element = updateLovTypeList[TCInputTypeUtils.getBasePropertyName(input.propertyName)];
            if (element) {
              return {
                ...input,
                value: element.value,
                dbValues: element.dbValue,
              };
            }
            return input;
          });
          setInputFields(updatedInputs);
        }
      }
    }
    logger.logTrace(`Exit ${handleDropDownValueChange.name}`);
  };

  // Form Action Handlers
  /*
    const uploadPhoto = async () => {
      if (hiddenFileInput && hiddenFileInput.current) {
        logger.logInformation('Action click image for Problem Report');
        hiddenFileInput.current.click();
      }
    };
  
    const onImageChange = (event:any) => {
      logger.logTrace(`Entered ${onImageChange.name}`);
      if (event.target.files && event.target.files[0]) {
        const localCapturedImageFile = event.target.files[0];
        if (localCapturedImageFile) {   
          // Uncomment this section if you want to add several pictures
          /*
          if(capturedImageFile)
          {
            var localCapturedImageFiles: File[] = [];
            if (capturedImageFiles) {
              if(capturedImageFiles.length > 0)
              localCapturedImageFiles = localCapturedImageFiles.concat(capturedImageFiles);
            }
            localCapturedImageFiles.push(capturedImageFile);
            setCapturedImageFiles(localCapturedImageFiles);
          }
          */
  /*
   setCapturedImageFile(localCapturedImageFile);

   if (localCapturedImageFile) {
     const reader = new FileReader();
     reader.onload = (e) => {
       setImageUrl(e.target?.result as string);
     };
     reader.readAsDataURL(localCapturedImageFile);
   }
 }
}
logger.logTrace(`Exit ${onImageChange.name}`);
};
*/
  const takePhoto = async () => {
    logger.logTrace(`Entered ${takePhoto.name}`);
    logger.logInformation('Action capture image for Problem Report');
    const analyticsManager = AnalyticsManager.getInstance();
    analyticsManager.logEvent(AnalyticsConstants.cmdAddObjectImage);
    const localCapturedImageFile = await captureImage();
    if (localCapturedImageFile) {
      /* Uncomment the lines below to support multiple attachments */
      /*
      if (capturedImageFile) {
        let localCapturedImageFiles: File[] = [];
        if (capturedImageFiles && capturedImageFiles.length > 0)
          localCapturedImageFiles = localCapturedImageFiles.concat(capturedImageFiles);
        localCapturedImageFiles.push(capturedImageFile);
        setCapturedImageFiles(localCapturedImageFiles);
      }
      */
      setCapturedImageFile(localCapturedImageFile);

      const reader: FileReader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target?.result as string);
      };
      reader.readAsDataURL(localCapturedImageFile);
    }
    logger.logTrace(`Exit ${takePhoto.name}`);
  };

  const isCreateButtonDisabled = (): boolean => {
    logger.logTrace(`Entered ${isCreateButtonDisabled.name}`);
    let retVal = false;
    for (const inputType of tcInputFields) {
      if (inputType.isRequired) {
        if (!inputType.value) {
          retVal = true;
          break;
        }
      }
    }
    if(timePickerError) {
      retVal = true;
    }
    logger.logTrace(`Exit ${isCreateButtonDisabled.name}`);
    return retVal;
  };

  const cancelCreation = () => {
    dialog.submit(undefined);
  };

  const handleTellme = () => {
    const analyticsManager: AnalyticsManager = AnalyticsManager.getInstance();
    analyticsManager.logEvent(AnalyticsConstants.cmdTellMeFeature);
    setShowForm(true);
  };

  const handleBackToForm = () => {
    setShowForm(false);
  };

  const handleSummarize = async () => {
    logger.logTrace(`Entered ${handleSummarize.name}`);
    logger.logInformation('Action Summarize Problem Report');
    try {
      const analyticsManager = AnalyticsManager.getInstance();
      analyticsManager.logEvent(AnalyticsConstants.actionSummarize);
      if (summarizeContent.length > 0) {
        setSummarizeInProgress(true);
        const inputOpenAI: OpenAIInput = {
          rawTranscript: summarizeContent,
          prType: selectedTypeName,
          prSchema: openAIPropList.current,
        };
        const openAiResult: any = await openApi.mutateAsync(inputOpenAI);
        const openAiProblem: any = JSON.parse(openAiResult.predictionAOAI);
        setSummarizeSessionId(openAiResult.sessionID);
        setIsFeedbackDisabled(false);
        setShowValidationMessage(true);

        if (openAiProblem) {
          // Note: description allows max 240 bytes (not utf-8 characters), utf-8 char has max 4 bytes
          // need to have unified input validation
          const enc = new TextEncoder();
          const dec = new TextDecoder("utf-8");
          openAiProblem.object_desc = dec.decode(enc.encode(summarizeContent).subarray(0, 235));
          TCInputTypeUtils.populateInputFieldsFromOpenAIResponse(tcInputFields, openAiProblem);
        }
        setSummaryContent('');
        setShowForm(false);
        setSummarizeInProgress(false);
      }
    } catch (error) {
      setSummarizeInProgress(false);
      logger.logError(`Failed to get summarize response: ${JSON.stringify(error)}`);
    }
    logger.logTrace(`Exit ${handleSummarize.name}`);
  };

  const handleFeedback = async (feedbackBool: boolean) => {
    logger.logTrace(`Entered ${handleFeedback.name}`);
    const analyticsManager = AnalyticsManager.getInstance();
    if (feedbackBool) {
      setIsPositiveFeedbackSelected(true);
      setIsNegativeFeedbackSelected(false);
      analyticsManager.logEventwithProperty(AnalyticsConstants.eventOpenAIFeedback, AnalyticsConstants.propValOk);
    } else {
      setIsNegativeFeedbackSelected(true);
      setIsPositiveFeedbackSelected(false);
      analyticsManager.logEventwithProperty(AnalyticsConstants.eventOpenAIFeedback, AnalyticsConstants.propValNotOk);
    }
    logger.logTrace(`Exit ${handleFeedback.name}`);
  };

  const getSubmitFormDataForCreatePR = (createData: CreateData): FormData => {
    const formData: FormData = new FormData();
    formData.append('BoName', createData.BoName);
    formData.append('PropertyNameValues', JSON.stringify(createData.PropertyNameValues));
    formData.append('CompoundCreateInput', JSON.stringify(createData.CompoundCreateInput));
    if (createData.dataToBeRelated) {
      formData.append('DataToBeRelated', JSON.stringify(createData.dataToBeRelated));
    }
    if (createData.workFlowData && createData.workFlowData.templateName) {
      formData.append('WorkflowData', JSON.stringify(createData.workFlowData));
    }

    let localCapturedImageFiles: File[] = [];
    if (capturedImageFile) {
      if ((typeof capturedImageFiles !== 'undefined') && (capturedImageFiles.length > 0)) {
        localCapturedImageFiles = localCapturedImageFiles.concat(capturedImageFiles);
      }
      localCapturedImageFiles.push(capturedImageFile);
      setCapturedImageFiles(localCapturedImageFiles);
    }

    if (localCapturedImageFiles && localCapturedImageFiles.length > 0) {
      for (var i = 0; i < localCapturedImageFiles.length; i++) {
        formData.append('files', localCapturedImageFiles[i] || undefined);
      }
    }

    return formData;
  }

  const handleCreateProblemReport = async () => {
    logger.logTrace(`Entered ${handleCreateProblemReport.name}`);
    const analyticsManager = AnalyticsManager.getInstance();
    analyticsManager.logEvent(AnalyticsConstants.cmdCreateObject);
    setCreatingPR(true);
    const requestData: ICreateData = TCInputTypeUtils.getCreatePanelSOADataRequest(selectedTypeName, attachedItem, tcInputFields);
    if (requestData) {
      // Calling the backend API for creation
      const formData: FormData = getSubmitFormDataForCreatePR(requestData);
      const response = await createProblem.mutateAsync(formData);
      setCreatingPR(false);
      if (response.error) {
        logger.logError("Error:" + response.error);
      } else {
        dialog.submit(undefined);
      }
    } else {
      throw new Error("Failed to create request data for create problem report request.");
    }

    if (!isFeedbackDisabled && summarizeSessionId && summarizeSessionId.trim() !== '') {
      let userFbInput: any = {}
      if (isPositiveFeedbackSelected || isNegativeFeedbackSelected) {
        userFbInput = {
          PositiveUserFeedback: isPositiveFeedbackSelected ? true : false,
        };
      }

      const formFields: any = {};
      tcInputFields.forEach((field) => {
        const propertyName = field.propertyName;
        const value = field.value;
        formFields[propertyName] = value;
      });

      const inputFeedback: FeedbackInput = {
        sessionID: summarizeSessionId,
        correctionForm: JSON.stringify(formFields),
        UserFeedback: userFbInput,
      };

      await RequestUtils.callTcTeamsApi(Constants.opGetFeedback, teamsContext.teamsUserCredential, teamsContext.teamcenter.session, JSON.stringify(inputFeedback));
    }

    logger.logTrace(`Exit ${handleCreateProblemReport.name}`);
  }


  const handleSubmit = async (ev: React.FormEvent) => {
    logger.logTrace(`Entered ${handleSubmit.name}`);
    const analyticsManager = AnalyticsManager.getInstance();
    analyticsManager.logEvent(AnalyticsConstants.actionCreateObject);
    ev.preventDefault();
    logger.logInformation('Action Create Problem Report.');
    handleCreateProblemReport();
    logger.logTrace(`Exit ${handleSubmit.name}`);
  };

  // UI Render Components

  const renderWidget = () => {
    logger.logTrace(`Entered ${renderWidget.name}`);
    const propList = tcInputFields.map((prop) => {
      if (prop.type === TCDataTypes.String) {
        if (prop.hasLOV) {
          const tcLovType: TCLOVDataType = tcLovFields[prop.propertyName] as TCLOVDataType;
          return (
            <>
              <Label id={prop.propertyName} required={prop.isRequired} htmlFor={prop.propertyDisplayName}>
                {prop.propertyDisplayName}:
              </Label>
              <Combobox
                id={prop.propertyName}
                value={prop.value}
                onOptionSelect={(event: any, d: any) => handleDropDownValueChange(d.optionValue, tcLovType)}
                onOpenChange={(event: any, d: any) => handleChangeBox(prop.propertyName, d, event)}
                onChange={(event: any) => onInputUpdate(prop, event)}
              >
                {
                  <LovRenderer tcLovType={tcLovType} tcInputFields={tcInputFields} />
                }
              </Combobox>
            </>
          )
        } else if (prop.maxLength > 128) {
          return (
            <>
              <Label id={prop.propertyName} required={prop.isRequired} htmlFor={prop.propertyDisplayName}>
                {prop.propertyDisplayName}:
              </Label>
              <Textarea size="large" resize="vertical" id={prop.propertyName} value={prop.value} onChange={handleStringPropertyChange} />
            </>
          );
        } else {
          return (
            <>
              <span>
                <Label id={prop.propertyName} required={prop.isRequired} htmlFor={prop.propertyDisplayName}>
                  {prop.propertyDisplayName}:
                </Label>
                {prop.pattern ? (
                  <Label className="create-problem-label-tab"> {prop.pattern} </Label>
                ) : null}
              </span>
              <Input
                required={prop.isRequired}
                type="text"
                id={prop.propertyName}
                value={prop.value}
                placeholder={prop.preferredPattern ? prop.preferredPattern : ""}
                onChange={handleStringPropertyChange}
              />
            </>
          );
        }
      } else if (prop.type === TCDataTypes.Boolean) {
        return (
          <>
            <Checkbox label={prop.propertyDisplayName} checked={prop.value === 'true' ? true : false} required={prop.isRequired} id={prop.propertyName} onChange={handleBooleanPropertyChange} />
          </>
        );
      } else if (prop.type === TCDataTypes.Date) {
        return (
          <>
            <Label
              id={prop.propertyName}
              required={prop.isRequired}
              htmlFor={prop.propertyDisplayName}
            >
              {prop.propertyDisplayName}
            </Label>
            <DateTime currentDate={selectedDate} onDateChange={handleDatePropertyChange} errorExists={handleTimePickerError} id={prop.propertyName} isTimeEnabled={prop.isTimeEnabled} />
          </>
        );
      } else if (prop.type === TCDataTypes.Integer) {
        return (
          <>
            <Label id={prop.propertyName} required={prop.isRequired} htmlFor={prop.propertyDisplayName}>
              {prop.propertyDisplayName}
            </Label>
            <Input
              required={prop.isRequired}
              id={prop.propertyName}
              type="number"
              pattern="[0-9]*"
              onChange={handleStringPropertyChange}
            />
          </>
        );
      } else if (prop.type === TCDataTypes.Object || prop.type === TCDataTypes.UntypedReference) {
        if (prop.hasLOV) {
          const tcLovType: TCLOVDataType = tcLovFields[prop.propertyName] as TCLOVDataType;
          return (
            <>
              <Label id={prop.propertyName} required={prop.isRequired} htmlFor={prop.propertyDisplayName}>
                {prop.propertyDisplayName}:
              </Label>
              <Combobox
                id={prop.propertyName}
                value={prop.value}
                onOptionSelect={(event: any, d: any) => handleDropDownValueChange(d.optionValue, tcLovType)}
                onOpenChange={(event: any, d: any) => handleChangeBox(prop.propertyName, d, event)}
                onChange={(event: any) => onInputUpdate(prop, event)}
              >
                {<LovRenderer tcLovType={tcLovType} tcInputFields={tcInputFields} />}
              </Combobox>
            </>
          )
        } else {
          return <></>;
        }
      } else {
        return <></>;
      }
    });
    logger.logTrace(`Exit ${renderWidget.name}`);
    return propList;
  };

  const renderSpinner = (isHidden: boolean, label: string) => {
    var showLabel: string = label + `${problemReport.displayName}`;
    return (
      <div hidden={isHidden}>
        <Spinner appearance="primary" label={showLabel} />
      </div>
    );
  };

  // Method to render the handleTell feature.
  const renderTellMe = () => {
    return (
      <>
        {renderSpinner(!summarizeInProgress, i18n.Summarizing)}
        <div hidden={summarizeInProgress}>
          <DialogBody className="problem-create-summaryFormContainer">
            <DialogContent className="problem-create-formContainer">
              <Label>
                {i18n.SummaryText}
              </Label>
              <Textarea
                required
                resize="vertical"
                disabled={summarizeInProgress}
                textarea={{ className: styles.textarea }}
                value={summarizeContent}
                onChange={(e) => setSummaryContent(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <div className="problem-create-dialog-action">
                <Button appearance="secondary" disabled={summarizeInProgress} onClick={handleBackToForm}>
                  {i18n.Back}
                </Button>
                <Button appearance="primary" disabled={summarizeContent ? false : true} onClick={handleSummarize}>
                  {i18n.Summarize}
                </Button>
              </div>
            </DialogActions>
          </DialogBody>
        </div>
      </>
    );
  };

  const getLoadingMessage = () => {
    if (getStyleSheet.isLoading) {
      const loadingLable = formatTemplateString(i18n.LoadingCreatePRPanelText, problemReport.displayName);
      return <Spinner appearance="primary" label={loadingLable} />;
    }
    return null;
  };

  const clearImageInput = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.value = '';
    }
    setImageUrl('')
  };

  // ProblemDetails renderer method.
  return (
    <>
      <div hidden={tcInputFields.length > 0}>
        {Object.keys(i18n).length > 0 && getLoadingMessage()}
      </div>

      <div hidden={tcInputFields.length === 0}>
        {!isCreatingPR && !showhandleTellMeForm ? (
          <form className="problem-create-formBody">
            <DialogBody>
              <div className="problem-header-wrapper">
                <div>
                  <Label id="ProblemReportTypes">
                    {i18n.Types}:
                  </Label>
                  <Combobox className="problem-types-input"
                    id="ProblemReportTypes"
                    value={selectedTypeDisplayName}
                    onOptionSelect={(event: any, d: any) => handleLovValueChange(d.optionValue)}
                  >
                    <div>
                      {types
                        ? types.map((type: any) => (
                          <Option key={type.typeName} style={{ height: '30px' }}>
                            {type.typeDisplayName}
                          </Option>
                        ))
                        : null}
                    </div>
                  </Combobox>
                </div>
                <Button
                  appearance="primary"
                  disabled={!ProblemDetailsUtils.checkIsHandleTellMeFeatureEnabled(teamsContext.configuration, selectedTypeName)}
                  onClick={handleTellme}
                  title={ProblemDetailsUtils.getTitleForHandleTellMeFeature(teamsContext.configuration, selectedTypeName)}
                  className="summarize-button"
                >
                  {i18n.TellMeText}
                </Button>
              </div>
              <DialogContent className="problem-create-formContainer">
                {renderWidget()}
                <ProblemItemPicker valueCallback={handleProblemItem} initialValue={attachedItem} boName={selectedTypeName}></ProblemItemPicker>
                <div>
                  <div hidden={teamsContext.clientType !== HostClientType.ios && teamsContext.clientType !== HostClientType.ipados && teamsContext.clientType !== HostClientType.android} className="problem-card-image-left-panel">
                    <Label>
                      {i18n.Attachments}:
                    </Label>
                  </div>
                  <div>
                    <div hidden={teamsContext.clientType !== HostClientType.ios && teamsContext.clientType !== HostClientType.ipados && teamsContext.clientType !== HostClientType.android} className="problem-card-image-right-panel">
                      <Button appearance="secondary" onClick={takePhoto}>
                        {i18n.AddText}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="problem-create-dialog-images">
                  <div hidden={imageUrl.length === 0}>
                    <div className="problem-card-image-container problem-card-image-left-panel" style={{ position: 'relative', width: '140px', height: '140px' }}>
                      <Image
                        src={imageUrl}
                        className={styles.Image}
                        shadow
                        fit="contain"
                        ref={(el) => {
                          if ((el !== undefined) && (el !== null)) {
                            setImageRef(el);
                          }
                        }}
                      />
                    </div>
                    <div className="problem-card-image-right-panel">
                      <Button appearance="secondary" onClick={clearImageInput}>
                        {i18n.DeleteText}
                      </Button>
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <div>
                  <div className="feedback-button-group" style={{ display: isFeedbackDisabled ? "none" : "flex" }}>
                    <Text className={'text-center'}>{i18n.TellMeFeatureQuestion}</Text>
                    <Button
                      className={isPositiveFeedbackSelected ? "feedback-button-selected" : ""}
                      appearance="secondary"
                      style={{
                        display: ProblemDetailsUtils.checkIsHandleTellMeFeatureEnabled(teamsContext.configuration, selectedTypeName) ? "block" : "none",
                      }}
                      icon={<ThumbLike24Filled fontSize={24} />}
                      onClick={() => handleFeedback(true)}
                      disabled={isFeedbackDisabled || isPositiveFeedbackSelected}
                    ></Button>
                    <Button
                      appearance="secondary"
                      className={isPositiveFeedbackSelected ? "feedback-button-selected" : ""}
                      style={{
                        display: ProblemDetailsUtils.checkIsHandleTellMeFeatureEnabled(teamsContext.configuration, selectedTypeName) ? "block" : "none",
                        backgroundColor: isNegativeFeedbackSelected ? "#e5e5e5" : "",
                      }}
                      icon={<ThumbDislike24Filled fontSize={24} />}
                      onClick={() => handleFeedback(false)}
                      disabled={isFeedbackDisabled || isNegativeFeedbackSelected}
                    ></Button>
                  </div>
                  <div className="create-button-group">
                    <Button
                      appearance="secondary"
                      onClick={cancelCreation}
                    >
                      {i18n.CloseText}
                    </Button>
                    <Popover open={showPopover} positioning={'above-start'} withArrow={true}>
                      <PopoverTrigger disableButtonEnhancement>
                        <Button
                          appearance="primary"
                          disabled={isCreateButtonDisabled()}
                          onClick={showValidationMessage ? () => setShowPopover(true) : handleSubmit}
                        >
                          {i18n.CreateText}
                        </Button>
                      </PopoverTrigger>
                      <PopoverSurface>
                        {i18n.OpenAIConfirmationText}
                        <DialogActions className='tell-me-human-oversight-actions'>
                          <Button appearance="secondary" onClick={handleSubmit}>
                            {i18n.CreateText}
                          </Button>
                          <Button appearance="primary" onClick={() => { setShowPopover(false) }}>
                            {i18n.EditText}
                          </Button>
                        </DialogActions>
                      </PopoverSurface>
                    </Popover>
                  </div>
                </div>
              </DialogActions>
            </DialogBody>
          </form>
        ) : (
          <>
            {showhandleTellMeForm ? (
              <>{renderTellMe()}</>
            ) : (
              <>{renderSpinner(false, i18n.CreatingSpinner)}</>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ProblemDetails;
