import { logger } from "../Logger";
import { Constants } from "../components/common/Constants";
import { CreateData, ICreateData, IGetDeclarativeStyleSheetsRequestData, ModelObject, StyleSheetType, WorkFlowData } from "../components/common/TcSOATypes";
import { ParticipantObject } from "../types/CommonTypes";
import { IAttachedItem, AttachedItem } from "../types/ProblemType";

// ***************** Teamcenter Data Types *********************************//
// *************************************************************************//
// *************     1 = Character         *********************************//
// *************     2 = Date              *********************************//
// *************     3 = Double            *********************************//
// *************     4 = Float             *********************************//
// *************     5 = Integer           *********************************//
// *************     6 = Boolean           *********************************//
// *************     7 = Short             *********************************//
// *************     8 = String            *********************************//
// *************     9 = Object Reference  *********************************//
//**************     10= untyped Reference *********************************//
//**************************************************************************//

/**@enum TCDataTypes
 * @description Enumeration to represent the various data types in Teamcenter Business Object Data Model.
 */
export enum TCDataTypes {
  Unknown = 0,
  Char    = 1,
  Date    = 2,
  Double  = 3,
  Float   = 4,
  Integer = 5,
  Boolean = 6,
  Short   = 7,
  String  = 8,
  Object  = 9,
  UntypedReference = 10
}

/**@type TCInputType
 * @description This type represent the Teamcenter input type fields for any Teamcenter Business Object.
 * @member propertyName {string} internal name of the input field.
 * @member propertyDisplayName {string} display name of the field.
 * @member type {TCDataTypes} enum representing the type of the field.
 * @member hasLOV {boolean} whether field is an LOV value.
 * @member maxLength {number} maximum allowed length of the data for the field.
 * @member isRequired {boolean} whether field is mandatory attribute.
 * @member isModifiable {boolean} whether field is modifiable attribute.
 * @member isDCP {boolean} whether field is a DCP attribute.
 * @member parentUid {string} Object UId of the parent object.
 * @member value {string} value of the field.
 * @member pattern {string} {optional} value pattern of the field.
 * @member dbValues {string} {optional} dbvalue of the field.
 * @member preferredPattern {string} {optional} preferred pattern of the field.
 * @member srcObjLsd {string} {optional} last modified date for the source object.
 * @member srcObjectTypeName {optional} object type for the source object.
 */
export type TCInputType = {
  propertyName: string;
  propertyDisplayName: string;
  type: TCDataTypes;
  hasLOV: boolean;
  maxLength: number;
  isRequired: boolean;
  isModifiable: boolean;
  isDCP: boolean;
  parentUid: string;
  value: string;
  pattern?: string;
  dbValues?: string;
  uiValues?: string;
  preferredPattern?: string;
  placeHolder?: string;
  srcObjLsd?: string;
  srcObjectTypeName?: string;
  isTimeEnabled?: boolean;
}

/**@class TCInputTypeUtils
 * @description: Class having various utility methods required for handling Teamcenter Business Object types and its creation.
 */
export class TCInputTypeUtils {

  /**@function getXRTRequestData
   * @description method to create the request data for calling stylesheet API.
   * @param boType {string} type of the business object.
   * @param stylesheetType {StyleSheetType} type of StyleSheetType.
   * @returns {IGetDeclarativeStyleSheetsRequestData} rewquest data for the API call.
   */
  public static getXRTRequestData (boType: string, stylesheetType: StyleSheetType, businessObject: ModelObject): IGetDeclarativeStyleSheetsRequestData {
    logger.logTrace(`Entered ${this.getXRTRequestData.name}`);
    let stylesheetReqData: IGetDeclarativeStyleSheetsRequestData;
    if (boType) {
      stylesheetReqData = {
        BusinessObject: businessObject,
        BusinessObjectType: boType,
        StyleSheetType: stylesheetType
      };
    }
    logger.logTrace(`Exit ${this.getXRTRequestData.name}`);
    return stylesheetReqData;
  }

  /**@function populateDBValueForTCProperty
   * @description Method to populate the value for the input Teamcenter property.
   * @param tcInputType {TCInputType} input Teamcenter property type.
   * @param serviceData {any} service data having model objects from XRT response.
   */
  public static populateDBValueForTCProperty(tcInputType: TCInputType, modelObjects: any) {
    logger.logTrace(`Entered ${this.populateDBValueForTCProperty.name}`);
    tcInputType.value = tcInputType.uiValues ? tcInputType.uiValues[0] : '';
    if (!tcInputType.value) {
      let boPropsValues: any;
      if (tcInputType.parentUid && modelObjects[tcInputType.parentUid]) {
        boPropsValues = modelObjects[tcInputType.parentUid].props;
        if (boPropsValues && boPropsValues[tcInputType.propertyName]) {
          const propDBValues: any = boPropsValues[tcInputType.propertyName];
          if (propDBValues.uiValues && propDBValues.uiValues[0]) {
            tcInputType.value = propDBValues.uiValues[0];
          }
        }
      }
    }
    logger.logTrace(`Exit ${this.populateDBValueForTCProperty.name}`);
  }

  /**@function extractTCInputTypes
   * @description Method to extract the input types for the given declarative JSON response.
   * @param viewModel {any} input declarative JSON response
   * @returns TCInputType[] list of extracted input types.
   */
  public static extractTCInputTypes(viewModel: any, modelObjects: any): TCInputType[] {
    logger.logTrace(`Entered ${this.extractTCInputTypes.name}`);
    const tcInputTypes: TCInputType[] = [];
    if (viewModel && modelObjects) {
      const viewModelJsonData: any = JSON.parse(viewModel);
      if (viewModelJsonData && viewModelJsonData.data) {
        const pageRendering: any[] = viewModelJsonData.data._pageRendering;
        if (pageRendering && pageRendering.length > 0) {
          let xrtPropertySection: any;
          if (pageRendering[0].children) {
            xrtPropertySection = TCInputTypeUtils.getXRTRenderPage(pageRendering[0]);
          } else {
            xrtPropertySection = pageRendering;
          }
          if (xrtPropertySection && xrtPropertySection.length > 0) {
            if (xrtPropertySection[0].parentUid) {
              const orderedAttributes: any[] = xrtPropertySection;
              const parentUID: string = xrtPropertySection[0].parentUid;
              if (viewModelJsonData.data.objects && viewModelJsonData.data.objects[parentUID]) {
                const parentObject: any[] = viewModelJsonData.data.objects[parentUID];
                if (parentObject.length > 0 && parentObject[0].props) {
                  const widgetsData: any = parentObject[0].props;
                  for (const key in orderedAttributes) {
                    const attribute: string = orderedAttributes[key].propertyName;
                    if (attribute && widgetsData[attribute]) {
                      const tcInputType: TCInputType = widgetsData[attribute] as TCInputType;
                      TCInputTypeUtils.populateDBValueForTCProperty(tcInputType, modelObjects);
                      tcInputType.pattern = tcInputType.preferredPattern ? widgetsData[attribute].preferredPattern : "";
                      tcInputTypes.push(tcInputType);
                    }
                  }
                }
              }
            }
          } else {
            logger.logError(`${Constants.tcXRTProperties} page not found in stylesheet response.`);
          }
        }
      }
    }
    if (tcInputTypes.length === 0) {
      logger.logError(`Failed to extract the Input Data Types.`);
    }
    logger.logTrace(`Exit ${this.extractTCInputTypes.name}`);
    return tcInputTypes;
  }

  /**@function getXRTRenderPage
   * @description method to get the specific XRT section properties.
   * @param pageRendering {any[]} page rendering having all section.
   * @returns {any} specific render section.
   */
  private static getXRTRenderPage(pageRendering: any): any {
    logger.logTrace(`Entered ${this.getXRTRenderPage.name}`);
    let retPageRender: any;
    if (pageRendering.children.length > 0 && pageRendering.children[0].children) {
      retPageRender = TCInputTypeUtils.getXRTRenderPage(pageRendering.children[0]);
    } else {
      retPageRender = pageRendering.children;
    }
    logger.logTrace(`Exit ${this.getXRTRenderPage.name}`);
    return retPageRender;
  }

  /**@function getCreatePanelSOADataRequest
   * @description method to generate the Create SOA request data.
   * @param prType {string} business object name.
   * @param attachedItem {IAttachedItem} Problem Item Object if defined.
   * @param populatedTCFields {TCInputType[]} array of populated input fields.
   * @returns CreateData structure for calling the REST API for creating Teamcenter BO.
   */
  public static getCreatePanelSOADataRequest(prType: string, attachedItem: IAttachedItem, populatedTCFields: TCInputType[]): ICreateData {
    logger.logTrace(`Entered ${this.getCreatePanelSOADataRequest.name}`);
    let retVal: ICreateData;
    const propertyMapData: any = {};
    const compoundCreateChange: any = {};
    const dataToBeRelated: any = {};
    const workFlowData: WorkFlowData = {
      templateName: ''
    };
    for (const inputType of populatedTCFields) {
      if (inputType.value) {
        if (!inputType.isDCP) {
          // For the LOv type values: Display value and internal values may be different.
          const value : string = inputType.hasLOV ? inputType.dbValues || "" : inputType.value || "";
          propertyMapData[inputType.propertyName] = [TCInputTypeUtils.getMaxAcceptableData(value, inputType.maxLength)];
        } else {
          // Handle the DCP property
          TCInputTypeUtils.handleDCPCreateProperty(inputType, compoundCreateChange, workFlowData);
        }
      }
    }

    // handling for the attaching problem item
    if (attachedItem) {
      dataToBeRelated[''] = [attachedItem.uid];
    }

    if (Object.keys(propertyMapData).length > 0) {
      retVal = {
        BoName: prType,
        PropertyNameValues: propertyMapData,
        CompoundCreateInput: compoundCreateChange,
        dataToBeRelated: dataToBeRelated,
        workFlowData: workFlowData
      };
    }

    logger.logTrace(`Exit ${this.getCreatePanelSOADataRequest.name}`);
    return retVal;
  }

  /**@function handleDCPCreateProperty
   * @description method to handle the compound property for the generating request for create SOA call.
   * @param tcInputtype {TCInputType} <input> compound property type.
   * @param compoundCreateInput {any} <output> generated compoundCreateInput structure for REST SOA call.
   * @param workFlowData {any} <output> generated workflow related data.
   */
  private static handleDCPCreateProperty(tcInputtype: TCInputType, compoundCreateInput: any, workFlowData: WorkFlowData) {
    logger.logTrace(`Entered ${this.handleDCPCreateProperty.name}`);
    const propertyNameTokens: string[] = tcInputtype.propertyName.split( '.' );
    const relatedProp: string = propertyNameTokens[1];

    let propertyName = '';
    let referencedBOName = '';
    if ( propertyNameTokens[0].startsWith( Constants.REF ) ) {
        const index: number = propertyNameTokens[0].indexOf( ',' );
        propertyName = propertyNameTokens[0].substring( 4, index ).trim();
        referencedBOName = TCInputTypeUtils.getBusinessObjectType(propertyNameTokens[0].substring( index + 1, propertyNameTokens[0].length - 1 ).trim());
    } else {
        propertyName = propertyNameTokens[0];
    }
    if (relatedProp !== Constants.propAwp0ProcessTemplates) {
      let compoundPropMap: any = [];
      if (compoundCreateInput[propertyName]) {
        compoundPropMap = compoundCreateInput[propertyName];
      }
      const compoundCreateChange: any = {}
      compoundCreateChange[relatedProp] = [TCInputTypeUtils.getMaxAcceptableData(tcInputtype.value, tcInputtype.maxLength)];
      const compoundData: CreateData = {
        BoName: referencedBOName,
        PropertyNameValues: compoundCreateChange,
        CompoundCreateInput: {}
      };
      compoundPropMap.push(compoundData);
      compoundCreateInput[propertyName] = compoundPropMap;
    } else {
      workFlowData.templateName = tcInputtype.value
    }
    logger.logTrace(`Exit ${this.handleDCPCreateProperty.name}`);
  }

  /**@function populateInputFieldsFromOpenAIResponse
   * @description Method to populate the input TC input fileds using the response from open AI
   * @param tcInputFields {TCInputType} TC input fields.
   * @param openAIResponse {any} response from openAI.
   */
  public static populateInputFieldsFromOpenAIResponse(tcInputFields: TCInputType[], openAIResponse: any) {
    logger.logTrace(`Entered ${this.populateInputFieldsFromOpenAIResponse.name}`);
    if (tcInputFields && openAIResponse) {
      const keyList: string[] = Object.keys(openAIResponse);
      for (const tcInputType of tcInputFields) {
        const propname: string = tcInputType.propertyName;
        const index: number = keyList.findIndex( item =>  propname.toLowerCase() === item.toLowerCase());
        if (index > -1)  {
          const value: string = openAIResponse[keyList[index]].toString();
          tcInputType.value = TCInputTypeUtils.getMaxAcceptableData(value, tcInputType.maxLength);
        }
      }
    }
    logger.logTrace(`Exit ${this.populateInputFieldsFromOpenAIResponse.name}`);
  }

  /**@function getMaxAcceptableData
   * @description Method to get the maximum accepted length of string for given property value.
   * @param attValue {string} property value.
   * @param maxLength {number} maximum acceptable length.
   * @returns {string} property value with acceptable length.
   */
  public static getMaxAcceptableData(attValue: string, maxLength: number): string {
    logger.logTrace(`Entered ${this.getMaxAcceptableData.name}`);
    let retVal: string = attValue;
    if (attValue.length > maxLength) {
      retVal = attValue.substring(0, maxLength);
    }
    logger.logTrace(`Exit ${this.getMaxAcceptableData.name}`);
    return retVal;
  }

  /**function getAllProblemItems
   * @description Method to extract all problem items from the model objects.
   * @param modelObjectList {ModelObject[]} response of getItem having list of model objects.
   * @returns {AttachedItem[]} returns list of extracted Problem Items.
   */
  public static getAllProblemItems(modelObjectList: ModelObject[]): AttachedItem[] {
    logger.logTrace(`Entered ${this.getAllProblemItems.name}`);
    const retItems: AttachedItem[] = [];
    if (modelObjectList && modelObjectList.length > 0) {
      for (const modelObject of modelObjectList) {
        const props: any = modelObject.props;
        if (props) {
          const prName: string = TCInputTypeUtils.getPropertyUIValueFromModelObjectProp(Constants.objectName, props);
          if (prName) {
            const prItem: AttachedItem = {
              id : TCInputTypeUtils.getPropertyUIValueFromModelObjectProp(Constants.itemId, props),
              name: prName,
              uid: modelObject.objectId,
              imageTicket: TCInputTypeUtils.getPropertyUIValueFromModelObjectProp(Constants.imageTicket, props)
            };
            retItems.push(prItem);
          }
        }
      }
    }
    logger.logTrace(`Exit ${this.getAllProblemItems.name}`);
    return retItems;
  }

  /**function getAllTCUserObjects
   * @description Method to extract all TC users from the search results.
   * @param searchResults {ModelObject[]} response of list object having list of users searched.
   * @returns {ParticipantObject[]} returns list of extracted TC users.
   */
  public static getAllTCUserObjects(searchResults: ModelObject[]): ParticipantObject[] {
    logger.logTrace(`Entered ${this.getAllTCUserObjects.name}`);
    const retItems: ParticipantObject[] = [];
    if (searchResults && searchResults.length > 0) {
      for (const userObject of searchResults) {
        const props: any = userObject.props;
        if (props) {
          const objName: string = TCInputTypeUtils.getPropertyUIValueFromModelObjectProp(Constants.objectString, props);
          if (objName) {
            const user: ParticipantObject = {
              type : userObject.type,
              displayValue: objName,
              uid: userObject.uid
            };
            retItems.push(user);
          }
        }
      }
    }
    logger.logTrace(`Exit ${this.getAllTCUserObjects.name}`);
    return retItems;
  }

  /**@function getPropertyUIValueFromModelObjectProp
   * @description Method to get the UI value from the model object props field.
   * @param propertyName {string} input property name
   * @param props {any} property structure having all the property values.
   * @returns {any} UI value of the input property name.
   */
  public static getPropertyUIValueFromModelObjectProp(propertyName: string, props: any): any {
    logger.logTrace(`Entered ${this.getPropertyUIValueFromModelObjectProp.name}`);
    let retVal: string = "";
    if (props) {
      const propPRName: any = props[propertyName];
      if (propPRName && propPRName.uiValues && propPRName.uiValues[0]) {
        retVal = propPRName.uiValues[0];
      }
    }
    logger.logTrace(`Exit ${this.getPropertyUIValueFromModelObjectProp.name}`);
    return retVal;
  }

  /**@function getPropertyName
   * @description method to get the property name from incoming property name value.
   * @param propName {string} input property name
   * @returns {string} property name value
   */
  public static getPropertyName(propName: string): string {
    logger.logTrace(`Entered ${this.getPropertyName.name}`);
    let retVal: string = propName;
    if (propName && propName.includes('REF')) {
      const splitValue: string[] = propName.split('.');
      if (splitValue && splitValue.length > 0) {
        retVal = splitValue[splitValue.length - 1];
      }
    }
    logger.logTrace(`Exit ${this.getPropertyName.name}`);
    return retVal;
  }

  /**@function getBusinessObjectType
   * @description method to get the business object type.
   * @param objectType {string} input business object type.
   * @returns {string} value of extracted business object type.
   */
  public static getBusinessObjectType(objectType: string): string {
    logger.logTrace(`Entered ${this.getBusinessObjectType.name}`);
    let retVal: string = objectType;
    if (objectType.toLowerCase().endsWith(Constants.opCrei)) {
      retVal = objectType.substring(0, objectType.length - 4)
    }
    logger.logTrace(`Exit ${this.getBusinessObjectType.name}`);
    return retVal;
  }

  /**@function getBusinessObjectUidFromTCType
   * @description method to get the business object Uid from given TCInputType.
   * @param tcInputType input TCInputType.
   * @returns object uid of the given TCInputType.
   */
  public static getBusinessObjectUidFromTCType(tcInputType: TCInputType) {
    logger.logTrace(`Entered ${this.getBusinessObjectUidFromTCType.name}`);
    let retVal = '';
    if (tcInputType) {
      if (tcInputType.isDCP) {
        if ('intermediateObjectUids' in tcInputType) {
          const intermediateObjectUids: string[] = tcInputType.intermediateObjectUids as string[];
          if (intermediateObjectUids.length === 1) {
            retVal = intermediateObjectUids[0];
          }
        }
      } else {
        retVal = tcInputType.parentUid;
      }
    }
    logger.logTrace(`Entered ${this.getBusinessObjectUidFromTCType.name}`);
    return retVal;
  }

  /**@function getBasePropertyName
   * @description method to get the base property name in case of DCP property or ref property.
   * @param incomingPropName {string} input property name.
   * @returns {string} returns base property name.
   */
  public static getBasePropertyName(incomingPropName: string): string {
    logger.logTrace(`Entered ${this.getBasePropertyName.name}`);
    let propName: string = incomingPropName;
      const dotSeparatedStrArray: string[] = propName.split('.');
      if (dotSeparatedStrArray.length > 1) {
        propName = dotSeparatedStrArray[dotSeparatedStrArray.length - 1];
      }
    logger.logTrace(`Exit ${this.getBasePropertyName.name}`);
    return propName;
  }
}
