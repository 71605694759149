import React from 'react';
import { TeamsFxContext } from '../../Context';
import { DatePicker, TimePicker, IComboBox ,ITimeRange, initializeIcons, TimePickerValidationResultData, IDatePickerStrings, ITimePickerStrings } from "@fluentui/react";

interface DateTimeProps {
  currentDate: Date;
  onDateChange: (date: Date, id:string) => void;
  errorExists: (isError: boolean) => void;
  id: string;
  isTimeEnabled: boolean | undefined;
}

const DateTime: React.FC<DateTimeProps> = ({ currentDate, onDateChange, errorExists, id, isTimeEnabled }) => {

  React.useEffect(() => {
    // Set the current date and time when the component mounts
    onDateChange(currentDate,id);
    initializeIcons();// Method to load icons
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const teamsContext = React.useContext(TeamsFxContext);
  const i18n = teamsContext.i18n;
  const isTimePickerErrorExists = React.useRef(false);

  const datePickerStrings: IDatePickerStrings = {
    days: [i18n.Sunday, i18n.Monday, i18n.Tuesday, i18n.Wednesday, i18n.Thursday, i18n.Friday, i18n.Saturday],
    shortDays: [i18n.SundayShort, i18n.MondayShort, i18n.TuesdayShort, i18n.WednesdayShort, i18n.ThursdayShort, i18n.FridayShort, i18n.SaturdayShort],
    months: [i18n.January, i18n.February, i18n.March, i18n.April, i18n.May, i18n.June, i18n.July, i18n.August, i18n.September, i18n.October, i18n.November, i18n.December],
    shortMonths: [i18n.JanuaryShort, i18n.FebruaryShort, i18n.MarchShort, i18n.AprilShort, i18n.MayShort, i18n.JuneShort, i18n.JulyShort, i18n.AugustShort, i18n.SeptemberShort, i18n.OctoberShort, i18n.NovemberShort, i18n.DecemberShort],
    goToToday: i18n.GoToToday,
    prevMonthAriaLabel: i18n.PrevMonthAriaLabel,
    nextMonthAriaLabel: i18n.NextMonthAriaLabel,
    prevYearAriaLabel: i18n.PrevYearAriaLabel,
    nextYearAriaLabel: i18n.NextYearAriaLabel,
    prevYearRangeAriaLabel: i18n.PrevYearRangeAriaLabel,
    nextYearRangeAriaLabel: i18n.NextYearRangeAriaLabel,
    closeButtonAriaLabel: i18n.CloseText,
    monthPickerHeaderAriaLabel: i18n.MonthPickerHeaderAriaLabel,
    yearPickerHeaderAriaLabel: i18n.YearPickerHeaderAriaLabel,
    isRequiredErrorMessage: i18n.IsRequiredErrorMessage
  };

  const timePickerStrings: ITimePickerStrings = {
    invalidInputErrorMessage: i18n.InvalidInputErrorMessage

  };

  const handleDateChange = (data: Date | null | undefined) => {
    if(data){
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();
      const newDate = new Date(data);
      newDate.setHours(hours);
      newDate.setMinutes(minutes);
      onDateChange(newDate,id);
    }
    
  };

  const isValidDate = (date: Date): boolean => {
    return !isNaN(date.getTime());
  };

  const timeRange: ITimeRange = {
    start: 0,
    end: 24,
  };

  const handleTimeChange = (_ev: any, newTime: Date) => {
    if(isValidDate(newTime)) {
      onDateChange(newTime, id);
    }
  };

  const onValidationResult = (_ev: React.FormEvent<IComboBox>, timePickerValidationResultData: TimePickerValidationResultData) => {
    if (timePickerValidationResultData.errorMessage !== undefined) {
      const isErrorMessage = timePickerValidationResultData.errorMessage.length > 0;

      errorExists(isErrorMessage);
      isTimePickerErrorExists.current = isErrorMessage;

    }
  };

  const getTimePickerValue = (): Date => {
    if(!isTimePickerErrorExists.current) {
      return currentDate;
    }
    return new Date(NaN);
  };

  const onFormatDate = (date?: Date) => {
    return !date
      ? ""
      : `${datePickerStrings.months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  }

  return (
    <>
      <div className="date-time-container">
        <div className={isTimeEnabled ? 'half-width' : 'full-width'}>
          <DatePicker
            placeholder={i18n.SelectDateText}
            value={currentDate}
            onSelectDate={handleDateChange}
            strings={datePickerStrings}
            formatDate={onFormatDate}
          />
        </div>
        {isTimeEnabled && (
          <div className="time-picker-container">
            <TimePicker
              placeholder={i18n.SelectTimeText}
              strings={timePickerStrings}
              value={getTimePickerValue()}
              onChange={handleTimeChange}
              dateAnchor={currentDate}
              timeRange={timeRange}
              onValidationResult={onValidationResult}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DateTime;
