import React, { useEffect,useContext,useState } from 'react';
import { Label } from '@fluentui/react-components';
import { TeamsFxContext } from "../Context";
import ParticipantSection from "./ParticipantSection";
import { ParticipantTypeObject } from "../../types/CommonTypes";
import { ModelObject } from "../common/TcSOATypes";
import { ParticipantsUtils } from "./ParticipantsUtils";

interface CardProps {
  modelObjects: any;
  object: ModelObject;
  inEditMode: boolean;
}

const ParticipantsInfo: React.FC<CardProps> = ({
  modelObjects,
  object,
  inEditMode
}:CardProps) => {

  const teamsContext = useContext(TeamsFxContext);
  const i18n = teamsContext.i18n;
  const [participants, setParticipants] = useState<ParticipantTypeObject[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const allParticipants = await ParticipantsUtils.getAllParticipants(modelObjects,object,teamsContext);
      setParticipants(allParticipants);
      setLoading(false);
    };
    fetchData();
  }, [modelObjects, object, teamsContext]);

  const refetchParticipants = async () => {
    const newModelObjects = await ParticipantsUtils.refetchObject(object,teamsContext);
    const newParticipantList = ParticipantsUtils.getModifiedParticipants(newModelObjects,participants,object);
    setParticipants(newParticipantList);
  };

  return (<div>
    <Label className='label-width'>
      {i18n.Participants}:
    </Label>
    {loading ? <div>{i18n.LoadingParticipants}</div> : <div>{participants.map((participantObject: ParticipantTypeObject) => (
      <ParticipantSection participantObject={participantObject} openedObject={object} inEditMode={inEditMode} refetch={refetchParticipants}/>
    ))}</div>}
  </div>);
};

export default ParticipantsInfo;    