import FilterToolbar from "../common/toolbar/FilterToolbar";
import LoginTollbar from "../common/toolbar/LoginToolbar";
import SearchToolbar from "../common/toolbar/SearchToolbar";
import {
  Toolbar,
  ToolbarGroup,
  ToolbarButton,
  ToolbarDivider,
  Menu,
  MenuTrigger,
  Button,
  MenuPopover,
  MenuItem,
  MenuList,
} from "@fluentui/react-components";
import * as React from "react";
import { TeamsFxContext } from "../Context";
import { useContext} from "react";
import { Add24Regular, ArrowSync24Regular } from "@fluentui/react-icons";
import { dialog } from "@microsoft/teams-js";
import { logger } from "../../Logger";
import { Constants } from "../common/Constants";
import { RequestUtils } from "../../services/RequestUtils";
import { ApiResponse } from "../../types/ApiRequest";

export const filterProblemReportStatus = {
  all: {
    value: Constants.allProblems,
    label: "",
  },
  open: {
    value: Constants.openProblems,
    label: "",
  },
  closed: {
    value: Constants.closedProblems,
    label: "",
  },
};

interface ProblemReportsToolbarProps {
  onSearchChanged: (query: string) => void;
  onFilterStatusChanged: (status: string) => void;
  filterStatus: string;
  disableButton: () => boolean;
  onRefreshButtonClicked: () => void;
}

const ProblemReportsToolbar: React.FunctionComponent<
  ProblemReportsToolbarProps
> = ({
  onSearchChanged,
  onFilterStatusChanged,
  filterStatus,
  disableButton,
  onRefreshButtonClicked,
}: ProblemReportsToolbarProps) => {
  const teamsContext = useContext(TeamsFxContext);
  const i18n = teamsContext.i18n;
  filterProblemReportStatus.all.label = i18n.AllProblems;
  filterProblemReportStatus.open.label = i18n.OpenText;
  filterProblemReportStatus.closed.label = i18n.ClosedText;
  const [baseChangeTypes, setBaseChangeTypes] = React.useState<any>();
  const isAppRunningOnPhone = () => {
    return (
      teamsContext.clientType === "android" ||
      teamsContext.clientType === "ios" ||
      teamsContext.clientType === "teamsPhones" ||
      teamsContext.clientType === "teamsRoomsAndroid"
    );
  };

  const openProblemReportCreationDialog = (property: any) => {
    logger.logTrace("Entered openProblemReportCreationDialog")
    const taskInfo = {
      title: i18n.CreateChangeText,
      url: window.location.origin + `/index.html#/problemdetails/${property.internalName}/${property.displayName}`,
      card: null,
      size: { height: 600, width: window.innerWidth > 1000 ? 450: 350 },
    };

    dialog.open(taskInfo, () => teamsContext.queryClient.invalidateQueries());
    logger.logTrace("Exit openProblemReportCreationDialog")
  }

  const populateMenuList = async() => {
    logger.logTrace("Entered populateMenuList")
    const getBaseChangeTypesResponse: ApiResponse = await RequestUtils.callTcTeamsApi(Constants.opgetBaseChangeTypes,teamsContext.teamsUserCredential,teamsContext.teamcenter.session,{});
    setBaseChangeTypes(getBaseChangeTypesResponse.data.baseChangeTypes);
    logger.logTrace("Exit populateMenuList")
  };

  return (
    <Toolbar aria-label="Default" className="toolbar">
      <ToolbarGroup role="presentation">
        <div>
          <Menu>
            <MenuTrigger disableButtonEnhancement>
            <Button className={isAppRunningOnPhone() ? "toolbar-button-mobile": ""}
            icon={<Add24Regular />}
            id="CreateChange"
            aria-label="New Problem Report"
            appearance="primary"
            disabled={disableButton()}
            onClick={() => populateMenuList()}
            >
              {teamsContext && isAppRunningOnPhone() ? "" : i18n.CreateChangeText}
            </Button>
            </MenuTrigger>
              <MenuPopover>
              <MenuList >
              {baseChangeTypes
                        ? baseChangeTypes.map((property: any) => (
                          <MenuItem className="menulist-width" id={property.internalName} onClick={() => openProblemReportCreationDialog(property)}>{property.displayName}
                          </MenuItem>
                        ))
                        : null}
                </MenuList>
              </MenuPopover>
           </Menu>
         </div>
      </ToolbarGroup>

      <ToolbarGroup role="navigation" className="toolbar-group">
        <SearchToolbar
          onSearchButtonClicked={onSearchChanged}
          disabled={disableButton()}
        />
        <FilterToolbar
          filterOptions={filterProblemReportStatus}
          filterStatus={filterStatus}
          onFilterStatusChanged={onFilterStatusChanged}
          disabled={disableButton()}
        />
        <ToolbarDivider />

        <ToolbarButton
          aria-label="Refresh"
          icon={<ArrowSync24Regular />}
          onClick={onRefreshButtonClicked}
        />
        <ToolbarDivider />

        <LoginTollbar />
      </ToolbarGroup>
    </Toolbar>
  );
};

export default ProblemReportsToolbar;
