import * as React from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { Button, Card, CardHeader, Spinner, DialogActions, DialogBody, DialogContent } from "@fluentui/react-components";
import { useLocalStorage } from "../../hooks/useStorage";
import { AnalyticsManager } from "../../services/analyticsUtility";
import { useUpdateUserPrivacy } from "../../hooks/useUpdateUserPrivacy";
import { UseMutationTypeQuery } from "../../types";
import { logger } from "../../Logger";
import DigitalProductExcellence from "./DigitalProductExcellence";
import ProductExcellenceProgram from "./ProductExcellenceProgram";
import AnalyticsConstants from "../common/AnalyticsConstants.json";
import { Constants } from "../common/Constants";
import { TeamsFxContext } from "../Context";

const DataPrivacy: React.FunctionComponent = () => {
  const teamsContext = React.useContext(TeamsFxContext);
  const i18n = teamsContext.i18n;
  const configuration: string | null = localStorage.getItem(Constants.cacheConfiguration);
  const [userConfiguration, setUserConfiguration] = useLocalStorage<any>(Constants.cacheUserConfiguration, undefined);
  const [showUpdateSpinner, setShowUpdateSpinner] = React.useState<boolean>(false);
  const [showUpdateButton, setShowUpdateButton] = React.useState<boolean>(false);
  let userConsentList: any = React.useRef<any>({});
  
  const updateUserPrivacy: UseMutationTypeQuery<any, any> = useUpdateUserPrivacy();

  // Logging data privacy popup visit
  React.useEffect(() => {
    async function startProcessing() {
      if (configuration) {
        const analyticsManager = AnalyticsManager.getInstance();
        analyticsManager.logPlace(AnalyticsConstants.panelDataPrivacy);
      }
    }
    startProcessing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initalize teams app and notify success of loading
  React.useEffect(() => {
    (async () => {
      await microsoftTeams.app.initialize();
      microsoftTeams.app.notifySuccess();
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      microsoftTeams.dialog.submit(undefined);
    })();
  }, [userConfiguration]);

  const updateDataPrivacy = (consentKey: string, value: boolean) => {
    logger.logTrace(`Entered ${updateDataPrivacy.name}`);
    const analyticsManager = AnalyticsManager.getInstance();
    const eventName: string = (consentKey === Constants.userDigitalProductTelemetryOptIn ? AnalyticsConstants.eventDigitalProductExcellence : AnalyticsConstants.eventProductExcellenceProgram);
    const propValue: string = (value ? AnalyticsConstants.propValAgree : AnalyticsConstants.propValDecline);
    analyticsManager.logEventwithProperty(eventName, propValue);
    if (!showUpdateButton) {
      setShowUpdateButton(true);
    }

    if (userConsentList) {
      userConsentList.current[consentKey] = value;
    }
    logger.logTrace(`Exit ${updateDataPrivacy.name}`);
  };

  const handleUpdate = async () => {
    logger.logTrace(`Entered ${handleUpdate.name}`);
    setShowUpdateSpinner(true);
    const analyticsManager = AnalyticsManager.getInstance();
    analyticsManager.logEvent(AnalyticsConstants.actionUpdateDataPrivacy);
    // Update the entry at the backend
    const response: any = await updateUserPrivacy.mutateAsync(userConsentList.current);
    if (!response.error) {
      setUserConfiguration({
        ...userConfiguration, // Spread the previous state and copy the old fields
        ...userConsentList.current //Spread the updated key-value pairs and copy
      });
      logger.logInformation(`User consent successfully updated.`);
    } else {
      logger.logError(`Failed to update User consent.`);
    }

    setShowUpdateSpinner(false);
    logger.logTrace(`Exit ${handleUpdate.name}`);
    
  };

  const cancelCreation = () => {
     microsoftTeams.dialog.submit(undefined);
  };

  return (
    <>
      <div className="data-privacy-dialog-container">
        <DialogBody>
          <DialogContent>
            <div className="data-privacy-list-container">
              <p>{i18n.DataPrivacyText1}</p>
              <p>
                {i18n.DataPrivacyText2}{" "}
                <b>
                  <a
                    href="https://www.sw.siemens.com/en-US/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {i18n.DataPrivacyText3}
                  </a>
                </b>
              </p>
              <div className="data-privacy-list">
                <Card>
                  <CardHeader
                    header={
                      <DigitalProductExcellence
                        configuration={userConfiguration}
                        updateDataPrivacy={updateDataPrivacy}
                      />
                    }
                  />
                </Card>
                <Card>
                  <CardHeader
                    header={
                      <ProductExcellenceProgram
                        configuration={userConfiguration}
                        isFirst={false}
                        updateDataPrivacy={updateDataPrivacy}
                      />
                    }
                  />
                </Card>
              </div>
            </div>
            <div hidden={!showUpdateSpinner} className="data-privacy-update-spinner">
              <Spinner
                appearance="primary"
                label="Updating the data privacy consent."
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className="data-privacy-action">
              <Button
                appearance="primary"
                disabled={!showUpdateButton}
                onClick={handleUpdate}
              >
                {i18n.UpdateText}
              </Button>
              <Button appearance="secondary" onClick={cancelCreation}>
                {i18n.Cancel}
              </Button>
            </div>
          </DialogActions>
        </DialogBody>
      </div>
    </>
  );
};

export default DataPrivacy;
