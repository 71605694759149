import {
  Body1,
  Button,
  Badge,
  Text,
  Subtitle1,
  Card,
  CardFooter,
  CardHeader,
  Image
} from "@fluentui/react-components";
import { CalendarLtr16Regular, Open16Filled } from "@fluentui/react-icons";
import { useContext } from "react";
import { dialog, DialogSize } from "@microsoft/teams-js";
import ProblemReportImage from "./ProblemReportImage";
import { TeamsFxContext } from "../Context";
import { Problem } from "../../types";
import { utcDateTimeToUserDateTime } from "../../services/utility";
import { logger } from "../../Logger";
import prDefaultImage  from '../../Images/pr-type-icon.svg';
import scDefaultImage  from '../../Images/sc-type-icon.svg';
import { AnalyticsManager } from "../../services/analyticsUtility";
import AnalyticsConstants from "../common/AnalyticsConstants.json";
import { ListObjectUtils } from "../componentutils/ListObjectUtils";
import { Constants } from "../common/Constants";

interface CardProps {
  problem: Problem;
  isOpenInTcButtonEnabled: boolean;
}

const ProblemReportCard: React.FunctionComponent<CardProps> = ({
  problem,
  isOpenInTcButtonEnabled,
}: CardProps) => {
  const teamsContext = useContext(TeamsFxContext);
  const i18n = teamsContext.i18n;

  if(problem){
    const defaultImage = problem.type === Constants.simpleChangeRevision ? scDefaultImage : prDefaultImage;
    const tcObjectImageTicket = problem.type === Constants.simpleChangeRevision ? problem.impactedItem?.imageTicket : problem.problemItem?.imageTicket;
    const imageTicket: string | undefined = problem.attachment && problem.attachment.length > 0 && problem.attachment[0].imageTicket ? problem.attachment[0].imageTicket : tcObjectImageTicket;

    const onViewProblemReportClicked = async () => {
      logger.logInformation("onViewProblemReportClicked");
      if( problem.type && problem.value ){
        const analyticsManager = AnalyticsManager.getInstance();
        analyticsManager.logEvent(AnalyticsConstants.openObjectInTc);
        const uri = problem.openInTeamcenterUri;
        if (uri) {
          window.open(uri, "_blank");
        } else {
          logger.logError("openInTeamcenterUri was not defined on the problem");
        }
      }
    };

    const openProblemReportInfoDialog = () => {
      logger.logTrace("Entered openProblemReportInfoDialog");
      if( problem.type && problem.value ){
        const analyticsManager = AnalyticsManager.getInstance();
        analyticsManager.logEvent(AnalyticsConstants.cmdOpenObject);
        const dialogSize: DialogSize = { height: 600, width: window.innerWidth > 1000 ? 450: 350 }; 
        const taskInfo = {
          title: problem.value,
          url: window.location.origin + '/index.html#/problemReportInfo/' + problem.uid +'/' + problem.type +'/' + problem.value,
          card: null,
          size: dialogSize,
        };
        dialog.open(taskInfo, () => teamsContext.queryClient.invalidateQueries());
      }
      else{
        logger.logError("Problem type or value is not defined in the problem object");
      }
      logger.logTrace("Exit openProblemReportInfoDialog");
    };

    return (
      <div className="problem-card-container">
        <Card>
          <CardHeader
            className="problem-card-header"
            header={
              <>
                <Badge className="problem-item-closure" appearance="tint">
                  {ListObjectUtils.getLeftBadgeLabel(problem, teamsContext.i18n)}
                </Badge>
                <Badge className="problem-item-id" appearance="filled" color="subtle">
                  {problem.props?.item_id?.uiValues[0]}
                </Badge>
                <div className="problem-card-image-container">
                  { imageTicket?
                    (<ProblemReportImage id={problem.props?.item_id?.uiValues[0]} imageTicket={imageTicket}/>):(
                      <div className="problem-report-image"><Image src={defaultImage} style={{ maxWidth: '100%', maxHeight: '100%' }}/></div>
                    )
                  } 
                </div>
              </>
            }
          />

          <CardHeader
            className="problem-card"
            header={
              <Body1>
                <Subtitle1 className="problem-item" truncate wrap={false}>
                  {problem.props?.object_name?.uiValues[0]}
                </Subtitle1>

                <div className="problem-item-date-container">
                  <CalendarLtr16Regular className="problem-item-date-icon" fontSize={16} />
                  <Text className="problem-item-date-value"> {utcDateTimeToUserDateTime(problem.props?.creation_date?.uiValues[0])}</Text>
                </div>
                <Text truncate wrap={false} className="problem-item">
                    {ListObjectUtils.getItemLabelText(problem, teamsContext.i18n)}
                </Text>
              </Body1>
            }
          />

          <CardFooter className="problem-card-footer">
            {isOpenInTcButtonEnabled && <Button onClick={onViewProblemReportClicked} icon={<Open16Filled />} iconPosition="after" className="open-in-button">{i18n.OpenInTeamcenterText}</Button>}
            <Button appearance="primary" onClick={openProblemReportInfoDialog}>
              {i18n.OpenText}
            </Button>
          </CardFooter>
        </Card>
      </div>
    );
  }
  else{
    return <></>;
  }
};

export default ProblemReportCard;
