import { Button, Input, Label, Listbox, Spinner } from '@fluentui/react-components';
import * as React from 'react';
import { Delete24Regular } from '@fluentui/react-icons';
import { useSearchTCObjects } from '../../hooks/useSearchTCObjects';
import { useDebounce } from '../../hooks/useDebounce';
import { AttachedItem } from '../../types';
import { TCInputTypeUtils } from '../../services/TCInputTypeUtils';
import { ModelObject } from '../common/TcSOATypes';
import { ListObjectUtils } from "../componentutils/ListObjectUtils";
import { TeamsFxContext } from '../Context';

type PickerProps = {
  valueCallback: (value?: AttachedItem) => void;
  initialValue?: AttachedItem;
  boName: string;
};

const ProblemItemPicker: React.FC<PickerProps> = (pickerProps) => {
  const teamsContext = React.useContext(TeamsFxContext);
  const i18n = teamsContext.i18n;
  const [problemItems, setProblemItems] = React.useState<AttachedItem[]>([]);
  const [showProblemItemResults, setShowProblemItemResults] = React.useState<boolean>(false);

  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const tcApi = useSearchTCObjects(debouncedSearchTerm);

  const [model, setModel] = React.useState<AttachedItem>();

  // Hook for searching the problem item using the search text from user.
  React.useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 0) {
      setShowProblemItemResults(true);
    } else {
      setProblemItems([]);
      setShowProblemItemResults(false);
    }
  }, [debouncedSearchTerm]);

  React.useEffect(() => {
    let problemItemList: AttachedItem[] = [];
    if (tcApi.getPRItemsResponse && tcApi.getPRItemsResponse.modelObjects) {
      problemItemList = TCInputTypeUtils.getAllProblemItems(tcApi.getPRItemsResponse.modelObjects as ModelObject[]);
    }
    setProblemItems(problemItemList);
  }, [tcApi.getPRItemsResponse]);

  React.useEffect(() => {
    if (pickerProps.initialValue !== undefined) {
      setModel(pickerProps.initialValue);
    }
  }, [pickerProps.initialValue]);

  const handleProblemItemSelect = (ev: React.MouseEvent) => {
    const item = problemItems.find((pi) => pi.uid === ev.currentTarget.id);
    setShowProblemItemResults(false);
    setModel(item);
    pickerProps.valueCallback(item);
  };

  const clearPicker = () => {
    setModel(undefined);
    pickerProps.valueCallback(undefined);
  };

  return (
    <>
      <Label htmlFor={'problem-item-input'}>{ListObjectUtils.getLabelForItemPicker(pickerProps.boName+'Revision', teamsContext.i18n)}</Label>
      {model !== undefined ? (
        <div>
          <Label>{model?.name}</Label>
          <Button appearance="transparent" icon={<Delete24Regular fontSize={24} />} onClick={() => clearPicker()}></Button>
        </div>
      ) : (
        <>
          <Input 
            autoComplete="true" 
            placeholder={`Search TC for ${ListObjectUtils.getLabelForItemPicker(pickerProps.boName+'Revision', teamsContext.i18n)}`} 
            id="problem-item-input" 
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {showProblemItemResults && (
            <div className="problemitem-picker-searchContainer">
              <Listbox className="problemitem-picker-searchResults">
                {tcApi.isFetching ? (
                  <div id="loading" key="loading" className="problemitem-picker-menuMessages">
                    <Spinner appearance="primary" size="small" />
                  </div>
                ) : problemItems?.length > 0 ? (
                  problemItems.map((item) => (
                    <div id={item.uid} key={item.uid} onClick={handleProblemItemSelect} className="problemitem-picker-searchResultItem">
                      {item.name}
                    </div>
                  ))
                ) : (
                  <div className="problemitem-picker-menuMessages">{i18n.NoItemsFoundText}</div>
                )}
              </Listbox>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ProblemItemPicker;
