import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Label, Input , Spinner, Dialog, DialogTrigger, DialogSurface, DialogBody, DialogTitle, DialogContent, DialogActions, Avatar} from '@fluentui/react-components';
import { AddRegular, SubtractRegular } from '@fluentui/react-icons';
import { ParticipantTypeObject, ParticipantObject, ListObjectRequestData } from "../../types/CommonTypes";
import { TCInputTypeUtils } from '../../services/TCInputTypeUtils';
import { useDebounce } from '../../hooks/useDebounce';
import { logger } from "../../Logger";
import { Constants } from '../common/Constants';
import { TeamsFxContext } from "../Context";
import { ModelObject } from '../common/TcSOATypes';
import { RequestUtils } from '../../services/RequestUtils';
import { ParticipantsUtils } from './ParticipantsUtils';

interface CardProps {
  participantObject: ParticipantTypeObject;
  openedObject: ModelObject
  inEditMode: boolean;
  refetch: () => Promise<void>;
}

const ParticipantSection: React.FC<CardProps> = ({
  participantObject,
  openedObject,
  inEditMode,
  refetch
}: CardProps) => {
  const teamsContext = useContext(TeamsFxContext);
  const i18n = teamsContext.i18n;
  const [open, setOpen] = useState(false);//Variable to store the Add Dialog open/close state.
  const [selectedRemoveParticipants, setSelectedRemoveParticipants] = useState<ParticipantObject[]>([]);//Variables to store the Remove participants in Participant.
  const [selectedParticipant, setSelectedParticipant] = useState<ParticipantObject[]>([]);//Variables to store the Selected participants in Add Dialog.
  const tcUsers = useRef<ParticipantObject[]>([]);//Variable to store the TC Users in Add Dialog.
  const [isAddingParticipants, setAddingParticipants] = useState<boolean>(false);//Variable to store the Add Participant API call state.
  const [isRemovingParticipants, setRemovingParticipants] = useState<boolean>(false);//Variable to store the Remove Participant API call state.
  const [searchTerm, setSearchTerm] = useState<string>('');//Variable to store the Search term in Add Dialog.
  const [showParticipantsResult, setShowParticipantsResult] = useState<boolean>(false);//Variable to store the Search result state in Add Dialog.
  const [isGetUsersCalled, setGetUsersCalled] = useState<boolean>(false);//Variable to store the Get Next Users API call state.
  const [startIndex, setStartIndex] = useState<number>(0);//Variable to store the Start Index in Add Dialog.
  const latestEndIndex = useRef<number>(0);//Variable to store the Latest End Index in Add Dialog.
  const endReached = useRef<boolean>(true);//Variable to store the End Reached state in Add Dialog.
  const contentRef = useRef<HTMLDivElement | null>(null);//Variable to store the reference of the content in Add Dialog.
  const debouncedSearchTerm = useDebounce(searchTerm, 500);//Variable to store the debounced Search term in Add Dialog.
  //const getNextUsers: UseMutationTypeQuery<any, any> = useGetNextUsers();//Variable to store the Get Next Users API call.

  //Methods in useEffect are kept in same hook not transferred to ParticipantsUtils.ts because of react-hooks/exhaustive-deps.
  useEffect(() => {
    const listObjectInput: ListObjectRequestData = {
      ObjectType: 'PeoplePicker',
      SearchCriteria: {
        WRKFLW_show_user_assignment_options: 'org_default',
        group: '',
        participantType:participantObject.typeName,
        providerContentType: 'GroupMember',
        searchString: debouncedSearchTerm,
        searchSubGroups: 'true',
        selectedObject: openedObject.uid,
      },
      FilterPropNameValuePairs:{
        'GroupMember.object_type':'GroupMember'
      },
      MaxToLoad: 20,
      StartIndex: startIndex
    };
    const fetchData = async () => {
      logger.logTrace(`Entered ${fetchData.name}`);
      try {
        const response: any = await RequestUtils.callTcTeamsApi(Constants.listObjects, teamsContext.teamsUserCredential, teamsContext.teamcenter.session, listObjectInput);
        if (response.data.searchResults && response.data.searchResults.length > 0 && response.data.cursor) {
          const tcUserObjects = TCInputTypeUtils.getAllTCUserObjects(response.data.searchResults as ModelObject[]);
          //Remove those participants from available area those are selected already.
          const tcUsersNotSelected = tcUserObjects.filter((user:ParticipantObject) => !selectedParticipant.find((selectedUser:ParticipantObject) => selectedUser.uid === user.uid));
          latestEndIndex.current = response.data.cursor.endIndex;
          endReached.current = response.data.cursor.endReached;
          if (startIndex === 0) {
            tcUsers.current = tcUsersNotSelected;
          } else {
            tcUsers.current = [...tcUsers.current, ...tcUsersNotSelected];
          }
        }
        setGetUsersCalled(false);
      } catch (error) {
        logger.logTrace('Error fetching data:'+ error);
        setGetUsersCalled(false);
      }
      logger.logTrace(`Exit ${fetchData.name}`);
    };
    if(open){
      if(startIndex === 0){
        setGetUsersCalled(true);
        tcUsers.current = [];
        fetchData();
        setShowParticipantsResult(true);
      }else{
        fetchData();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open, startIndex, debouncedSearchTerm]);

  //Methods updating useState variables.
  //Method to remove the selected participant from the list in Selected Users area in Add Dialog.
  const handleRemoveSelection = (user:ParticipantObject) => {
    logger.logTrace(`Entered ${handleRemoveSelection.name}`);
    const updatedParticipants = selectedParticipant.filter(participant => participant.uid !== user.uid);
    setSelectedParticipant(updatedParticipants);
    //Add the removed participant back to the Available area.
    tcUsers.current = [...tcUsers.current, user];
    logger.logTrace(`Exit ${handleRemoveSelection.name}`);
  };

  //Method to handle the user selection in Available area in Add Dialog.
  const handleUserSelect = (newSelectedParticipant:ParticipantObject) => {
    logger.logTrace(`Entered ${handleUserSelect.name}`);
    if(participantObject.multipleAssignee === false){
      if(selectedParticipant.length > 0){
        const updatedTcUsers = tcUsers.current.filter((user:ParticipantObject) => user.uid !== newSelectedParticipant.uid);
        tcUsers.current = [...updatedTcUsers, selectedParticipant[0]];
      }else{
        const updatedTcUsers = tcUsers.current.filter((user:ParticipantObject) => user.uid !== newSelectedParticipant.uid);
        tcUsers.current = updatedTcUsers;
      }
      setSelectedParticipant([newSelectedParticipant]);
    }else if(!selectedParticipant.find((user:ParticipantObject) => user.uid === newSelectedParticipant.uid)){
      const updatedParticipants = [...selectedParticipant, newSelectedParticipant];
      setSelectedParticipant(updatedParticipants);
      const updatedTcUsers = tcUsers.current.filter((user:ParticipantObject) => user.uid !== newSelectedParticipant.uid);
      tcUsers.current = updatedTcUsers;
    }
    logger.logTrace(`Exit ${handleUserSelect.name}`);
  };

  //Method to add the call Add Participant API with Selected Users in Add Dialog.
  const handleAddParticipant = async () => {
    logger.logTrace(`Entered ${handleAddParticipant.name}`);
    setAddingParticipants(true);
    const addParticipantInput= ParticipantsUtils.getAddParticipantInput(selectedParticipant, participantObject, openedObject);
    const getAddParticipantResponse = await RequestUtils.callTcTeamsApi(Constants.addParticipant,teamsContext.teamsUserCredential, teamsContext.teamcenter.session, addParticipantInput);
    if(getAddParticipantResponse.error){
      logger.logError("Error:" + getAddParticipantResponse.error);
    } else {
      setSelectedParticipant([]);
      await refetch();
      setOpen(false);
      setAddingParticipants(false);
    }
    logger.logTrace(`Exit ${handleAddParticipant.name}`);
  };

  //Method to remove the selected participant from the list in Participant List area in Participant section.
  const handleRemoveParticipants = async () => {
    logger.logTrace(`Exit ${handleRemoveParticipants.name}`);
    setRemovingParticipants(true);
    const removeParticipantsInput= ParticipantsUtils.getRemoveParticipantsInput(selectedRemoveParticipants, openedObject);
    const getRemoveParticipantResponse = await RequestUtils.callTcTeamsApi(Constants.removeParticipant,teamsContext.teamsUserCredential, teamsContext.teamcenter.session, removeParticipantsInput);
    if(getRemoveParticipantResponse.error){
      logger.logError("Error:" + getRemoveParticipantResponse.error);
    } else {
      setSelectedRemoveParticipants([]);
      await refetch();
      setRemovingParticipants(false);
    }
    logger.logTrace(`Exit ${handleRemoveParticipants.name}`);
  };

  //Method to update remove participant selection in Participant Section.
  const setRemoveSelection = (assignee: ParticipantObject) => {
    logger.logTrace(`Exit ${setRemoveSelection.name}`);
    if(ParticipantsUtils.isRemoveParticipantSelected(assignee,selectedRemoveParticipants)){
      setSelectedRemoveParticipants(selectedRemoveParticipants.filter((user:ParticipantObject) => user.uid !== assignee.uid));
    }else{
      setSelectedRemoveParticipants([...selectedRemoveParticipants, assignee]);
    }
    logger.logTrace(`Exit ${setRemoveSelection.name}`);
  };

  //Method to check Remove participant button disability.
  const isRemoveDisabled = () => {
    return selectedRemoveParticipants.length === 0 || inEditMode;
  };

  //Method to check Add participant button disability.
  const isAddParticipantDisabled = () => {
    //If Multiple Assignee is allowed, then no need to check for the assignee list length.
    //If Multiple Assignee is not allowed, then check for the assignee list length.
    //If assignee list length is equal to 1, then disable the Add Participant button.
    const isAddButtonDisabled = (participantObject.multipleAssignee === false ?
       participantObject.assigneeList.length >= 1 : false) || inEditMode;
    return isAddButtonDisabled;
  };

  //Method to check Add button disability on Add Participant Dialog.
  const isAddButtonDisabled = () => {
    return selectedParticipant.length === 0;
  };

  //Method to handle the scroll event in Search area in Add Dialog.
  const handleScroll = ()=>{
    const target = contentRef.current;
    if(target){
      // Check if the user has scrolled to the bottom
      if ((target.scrollTop + target.clientHeight > (target.scrollHeight-10))&& !endReached.current ){
        setStartIndex(latestEndIndex.current + 1);
      }
    }
  };

  //Method to handle the search term change in Search area in Add Dialog.
  const handleSearchTermChange = (searchTerm: string) => {
    setStartIndex(0);
    setSearchTerm(searchTerm);
  };

  //Method to render the User in Add Dialog.
  const renderUser = (item: ParticipantObject, section: string,) => {
    const [group, role, name] = item.displayValue.split('/');
    const separatedName = name.split("(")[0].trim();
    const renderIcon = section === 'available' ? <AddRegular className='add-regular-icon'/> : <SubtractRegular className='add-regular-icon'/>;
    const iconTitle = section === 'available' ? i18n.SelectUser : i18n.RemoveUser;
    let idAttributeValue = section === 'available' ? Constants.addParticipantId : Constants.removeParticipantId;

    return(
      <div className='user-container'>
        <Button
          title={iconTitle} 
          id={idAttributeValue}
          shape='circular'
          size='small'
          icon={renderIcon}
          className='add-user-button'
          onClick={()=>{section === 'available' ? handleUserSelect(item) : handleRemoveSelection(item)}}
        ></Button>
        <div className='avatar-container'>
          <Avatar name={name}/>
        </div>
        <div className='user-details-container'>
          <Label>{separatedName}</Label>
          <Label>{i18n.Group}: {group}</Label>
          <Label>{i18n.Role}: {role}</Label>
        </div>
      </div>
    );
  };

  return (
    <div className='participant-section'>
      <div>
        <div className='participant-section-header'>
          <Label className='label-width participant-section-label'>{participantObject.displayName}</Label>
          <div className='participant-section-button'>
            <Dialog open={open} onOpenChange={(event, data) => setOpen(data.open)}>
              <DialogTrigger disableButtonEnhancement>
                <Button disabled={isAddParticipantDisabled()}
                  id={"Add"+participantObject.displayName}
                  title={i18n.AddText} 
                  icon={<AddRegular className='people-add-regular-icon'/>}
                ></Button>
              </DialogTrigger >
              <DialogSurface>
                { !isAddingParticipants ? (
                  <DialogBody>
                    <DialogTitle>
                      {i18n.AddText} {participantObject.displayName}
                    </DialogTitle>
                    <DialogContent>
                      <div className="add-participant-container">
                        <div className="search-area">
                          <Label className='label-width'>{i18n.AvailableUsers}</Label>
                          <Input 
                            autoComplete="true" 
                            placeholder={'Search TC for users'}
                            value={searchTerm}
                            id="tc-users-input" 
                            onChange={(e) => handleSearchTermChange(e.target.value)}
                          />
                          {showParticipantsResult && (
                            <div ref={contentRef} className='user-list-area' onScroll={handleScroll}>
                              {isGetUsersCalled ? (
                                <div id="loading" key="loading" className="problemitem-picker-menuMessages">
                                  <Spinner appearance="primary" size="small" />
                                </div>
                              ) : tcUsers.current.length > 0 ? (
                                tcUsers.current.map((item) => (
                                  <div id={item.uid} key={item.uid}>
                                    {renderUser(item, 'available')}
                                  </div>
                                ))
                              ) : (
                                <div className="problemitem-picker-menuMessages">{i18n.NoUsersText}</div>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="splitter" id="splitter"></div>
                        <div className="selected-area">
                          <Label className='label-width'>{ participantObject.multipleAssignee ? i18n.SelectedUsers : i18n.SelectedUser }</Label>
                          <div className='selected-user-area'>
                            {selectedParticipant.map((participant: ParticipantObject) => (
                              <div>
                                {renderUser(participant,'selected')}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </DialogContent>
                    <DialogActions >
                      <div className="participant-action-buttons">
                        <DialogTrigger disableButtonEnhancement >
                          <Button appearance="secondary">{i18n.CloseText}</Button>
                        </DialogTrigger>
                        <Button
                          appearance="primary" 
                          onClick={()=>{handleAddParticipant()}}
                          disabled={isAddButtonDisabled()}
                        >{i18n.AddText}</Button>
                      </div>
                    </DialogActions>
                  </DialogBody>
                ):(<Spinner appearance="primary" label={i18n.AddingParticipantsText} />)}
              </DialogSurface>
            </Dialog>
            <Button disabled={ isRemoveDisabled() } 
              title={i18n.RemoveText} 
              id={"Remove"+participantObject.displayName}
              icon={<SubtractRegular className='people-add-regular-icon'/>} 
              onClick={()=>{handleRemoveParticipants()}}
            ></Button>
          </div>
        </div>
        <div>
          {participantObject.assigneeList.map((assignee: ParticipantObject) => (
            <div 
              className='participant-section-list' 
              tabIndex={0} 
              onClick={()=>setRemoveSelection(assignee)}
            >
              <Avatar name={assignee.displayValue} className='avatar-container'/>
              <span className={ParticipantsUtils.isRemoveParticipantSelected(assignee,selectedRemoveParticipants) ? 'selected-participant' : 'not-selected-participant'}>
                {assignee.displayValue}
              </span>
            </div>
          ))}
        </div>
      </div>
      <Dialog open={isRemovingParticipants}>
        <DialogSurface>
          <Spinner appearance="primary" label={i18n.RemovingParticipantsText} />
        </DialogSurface>
      </Dialog>
    </div>
  );
};
export default ParticipantSection;