import * as React from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { Button, Card, CardHeader, Spinner, DialogActions, DialogBody, DialogContent } from "@fluentui/react-components";
import { useLocalStorage } from "../../hooks/useStorage";
import ProductExcellenceProgram from "./ProductExcellenceProgram";
import { logger } from "../../Logger";
import { Constants } from "../common/Constants";
import { AnalyticsManager } from "../../services/analyticsUtility";
import { UseMutationTypeQuery } from "../../types";
import { useUpdateUserPrivacy } from "../../hooks/useUpdateUserPrivacy";
import AnalyticsConstants from "../common/AnalyticsConstants.json";
import { TeamsFxContext } from "../Context";

const DataPrivacyInitial: React.FunctionComponent = () => {
  const teamsContext = React.useContext(TeamsFxContext);
  const i18n = teamsContext.i18n;
  const configuration: string | null = localStorage.getItem(Constants.cacheConfiguration);
  const [userConfiguration, setUserConfiguration] = useLocalStorage<any>(Constants.cacheUserConfiguration, undefined);
  const [showUpdateSpinner, setShowUpdateSpinner] = React.useState<boolean>(false);
  let userConsentList: any = React.useRef<any>({});

  const updateUserPrivacy: UseMutationTypeQuery<any, any> = useUpdateUserPrivacy();

  const updateDataPrivacy = (consentKey: string, value: boolean) => {
    logger.logTrace(`Entered ${updateDataPrivacy.name}`);
    const analyticsManager = AnalyticsManager.getInstance();
    const propValue: string = (value ? AnalyticsConstants.propValAgree : AnalyticsConstants.propValDecline);
    analyticsManager.logEventwithProperty(AnalyticsConstants.eventProductExcellenceProgram, propValue);
    if (consentKey === Constants.userProductExcellenceTelemetryOptIn) {
      userConsentList.current[consentKey] = value;
    }
    logger.logTrace(`Exit ${updateDataPrivacy.name}`);
  };

  const handleUpdate = async (value: boolean) => {
    logger.logTrace(`Entered ${handleUpdate.name}`);
    updateDataPrivacy(Constants.userProductExcellenceTelemetryOptIn, value);
    setShowUpdateSpinner(true);
    // Update the entry at the backend
    const response: any = await updateUserPrivacy.mutateAsync(userConsentList.current);
    if (!response.error) {
      setUserConfiguration({
        ...userConfiguration,  // Spread the previous state
        ...userConsentList.current // Spread the updated key-value pairs and update
      });
      logger.logInformation(`User consent successfully updated.`);
    } else {
      logger.logError(`Failed to update User consent successfully.`);
    }

    setShowUpdateSpinner(false);
    logger.logTrace(`Exit ${handleUpdate.name}`);
  };
  
  // Logging data privacy popup visit
  React.useEffect(() => {
    async function startProcessing() {
      if (configuration) {
        const analyticsManager = AnalyticsManager.getInstance();
        analyticsManager.logPlace(AnalyticsConstants.panelDataPrivacy);
      }
    }
    startProcessing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initalize teams app and notify success of loading
  React.useEffect(() => {
    (async () => {
      await microsoftTeams.app.initialize();
      microsoftTeams.app.notifySuccess();
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      microsoftTeams.dialog.submit(undefined);
    })();
  }, [userConfiguration]);

  return (
    <>
      <div className="data-privacy-dialog-container">
        <DialogBody>
          <DialogContent>
          <div className="data-privacy-list-initial">
                <Card>
                  <CardHeader
                    header={
                      <ProductExcellenceProgram
                        configuration={userConfiguration}
                        isFirst={true}
                        updateDataPrivacy={updateDataPrivacy}
                      />
                    }
                  />
                </Card>
                <div hidden={!showUpdateSpinner} className="data-privacy-update-spinner">
                  <Spinner
                    appearance="primary"
                    label={i18n.UpdatingUserPrivacyText}
                  />
                </div>
              </div>
          </DialogContent>
          <DialogActions>
            <div className="data-privacy-initial-action">
              <Button
                appearance="secondary"
                onClick={() => handleUpdate(false)}
              >
                {i18n.DeclineText}
              </Button>
              <Button appearance="primary" onClick={() => handleUpdate(true)}>
                {i18n.AgreeText}
              </Button>
            </div>
          </DialogActions>
        </DialogBody>
      </div>
    </>
  );
};

export default DataPrivacyInitial;