import * as microsoftTeams from "@microsoft/teams-js";
import { logger } from '../../Logger';
import { Configuration } from "../../services/Configuration";
import { Constants } from "../../components/common/Constants";
import { RequestUtils } from "../../services/RequestUtils";
import { ApiResponse } from "../../types/ApiRequest";
import { AuthCallResponse, ISAMConfig } from "../../types/CommonTypes";

/**@class: AuthenticationUtils
 * @description: Defines all the methods related to authentication to be used in the frontend application.
 */
export class AuthenticationUtils {

  /**@function authenticateAsync
   * @description method to perform the authentication in asynch mode.
   * @param sessionId {string} Application session id.
   * @param teamsUserCredential {any} teams user credentials
   * @param samConfiguration {ISAMConfig} SAM configuration if enabled else undefined.
   * @returns authenticateCall
   */
  public static async authenticateAsync(sessionId: string, teamsUserCredential: any, samConfiguration: ISAMConfig): Promise<AuthCallResponse> {
    logger.logTrace(`Entered ${this.authenticateAsync.name}`);
    const retVal: AuthCallResponse = {
      sessionId: undefined,
      userId: undefined,
      userUid: undefined,
      locale: undefined,
      error: undefined
    };
    try {
      let urlString = `${window.location.origin}/index.html#/teamcenterauthpopup?sessionId=${encodeURIComponent(sessionId)}`;
      if (samConfiguration && samConfiguration.SAMEnabled && samConfiguration.samLoginUrl) {
        const userInfo = await teamsUserCredential.getUserInfo();
        urlString = `${samConfiguration.samLoginUrl}&login_hint=${userInfo.preferredUserName}`;
      }
      const popup: microsoftTeams.authentication.AuthenticatePopUpParameters = {
        url: urlString,
        width: 600,
        height: 535,
      };
    
      logger.logCorrelationCreate();
      logger.setActionId("authenticateAsync");
      logger.setRequestId();
      logger.logInformation("authenticateAsync() called.");

      // microsoftTeams.authentication.notifySuccess(stringCode) is called in the popup window
      // and will return here the code from TeamCenter
      await microsoftTeams.app.initialize();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const teamCenterCodeString: any = await microsoftTeams.authentication.authenticate(popup);

      logger.clearActionId();
      logger.clearRequestId();
      
      if (samConfiguration && samConfiguration.SAMEnabled ) {
        // For SAM Enabled Authorizations
        await this.initiateSAMEnabledLoginSSO(teamCenterCodeString, teamsUserCredential, retVal);
      } else {
        // depending on the browser, the result is either a string or an object (IOS returns a string, Android returns an object)
        if (typeof teamCenterCodeString === "object") {
          this.populateAuthCallResponse(retVal, teamCenterCodeString);
        } else {
          const teamCenterCode: any = JSON.parse(teamCenterCodeString);
          this.populateAuthCallResponse(retVal, teamCenterCode);
        }
      }
      
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      retVal.error = error;
    }
    logger.logTrace(`Exit ${this.authenticateAsync.name}`);
    return retVal;
  }

  private static populateAuthCallResponse(authCallResponse: AuthCallResponse, teamcenterCode: any) {
    logger.logTrace(`Entered ${this.populateAuthCallResponse.name}`);
    authCallResponse.sessionId = teamcenterCode.code;
    authCallResponse.userId = teamcenterCode.userid;
    authCallResponse.userUid = teamcenterCode.useruid;
    authCallResponse.locale = teamcenterCode.locale;
    logger.logTrace(`Exit ${this.populateAuthCallResponse.name}`);
  }

  /**
   * @function checkForSAMAuthentication
   * @description method to check for SAM enabled Authentication from the configuration.
   * @param configuration {any} configuration value from the control plane database.
   * @returns {ISAMConfig} SAM configuration 
   */
  public static checkForSAMAuthentication(configuration: any): ISAMConfig {
    logger.logTrace(`Entered ${this.checkForSAMAuthentication.name}`);
    let retVal: ISAMConfig;
    const authenticationValue: string = Configuration.getConfigurationForAttribute(Constants.authorizerType, configuration);
    if (authenticationValue === Constants.SAMAuthentication) {
      retVal = {
        authorizerType: authenticationValue,
        SAMEnabled: true,
        samLoginUrl: Configuration.getConfigurationForAttribute(Constants.samLoginUrl, configuration)
      }
    }    
    logger.logTrace(`Exit ${this.checkForSAMAuthentication.name}`);
    return retVal;
  }

  /**@function initiateSAMEnabledLoginSSO
   * @description method to initiate the SAM login SSO flow.
   * @param SAMTokenString {any} SAM token string received from SAM Auth server. 
   * @param teamsUserCredential {any} MS teams user crendentials
   * @param authResponse {AuthCallResponse} output object having Teamcenter session.
   */
  public static async initiateSAMEnabledLoginSSO(SAMTokenString: any, teamsUserCredential: any, authResponse: AuthCallResponse): Promise<void> {
    logger.logTrace(`Entered ${this.initiateSAMEnabledLoginSSO.name}`);
    let respObject: any;
    if (typeof SAMTokenString === "object") {
      respObject = SAMTokenString;
    } else {
      respObject = JSON.parse(SAMTokenString);
    }

    if (respObject) {
      const reqBody = {
        samAccessToken: respObject.accessToken,
        samRefreshToken: respObject.refreshToken,
      };
      const tcSSOLoginResponse: ApiResponse = await RequestUtils.callTcTeamsApi(Constants.oploginSSO, teamsUserCredential, undefined, reqBody);
      if (!tcSSOLoginResponse.error) {
        authResponse.sessionId = tcSSOLoginResponse.data.sessionId;
        authResponse.userId = tcSSOLoginResponse.data.tcUserId;
        authResponse.userUid = tcSSOLoginResponse.data.tcUserUid;
        authResponse.isTokenRefreshed = tcSSOLoginResponse.data.isTokenRefreshed;
        if (authResponse.isTokenRefreshed) {
          authResponse.accessToken = tcSSOLoginResponse.data.accessToken;
          authResponse.refreshToken = tcSSOLoginResponse.data.refreshToken;
        } else {
          authResponse.accessToken = respObject.accessToken;
          authResponse.refreshToken =respObject.refreshToken;
        }
        authResponse.ecaId = tcSSOLoginResponse.data.ecaId;
      } else {
        authResponse.error = tcSSOLoginResponse.error;
      }
    }
    logger.logTrace(`Exit ${this.initiateSAMEnabledLoginSSO.name}`);
  }
}