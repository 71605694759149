import { Problem } from "../../types";
import { Constants } from "../common/Constants";

/**@class ListObjectUtils
 * @description: Class having various utility methods required for handling listing of Objects.
 */
export class ListObjectUtils {
    /**
   * @function getLeftBadgeLabel
   * @description method to get left side label on card.
   * @param problem {Problem} Object Info.
   * @param problem {i18n} Localized messages objects.
   * @returns {String} left side label on card. 
   */
    public static getLeftBadgeLabel(problem: Problem, i18n: any): string {
        let badgeValue: string;
        let badgeLabel: string;
        switch (problem.className) {
            case Constants.simpleChangeRevision:
                badgeValue = problem.props.CMMaturity?.uiValues[0];
                badgeLabel = i18n.ChangeMaturity;
                break;
            default:
                badgeValue = problem.props.CMClosure?.uiValues[0];
                badgeLabel = i18n.ClosureText;
        }
        return `${badgeLabel}: ${badgeValue}`;
    }

    /**
   * @function getItemLabelText
   * @description method to get item label text.
   * @param problem {Problem} Object Info.
   * @param problem {i18n} Localized messages objects.
   * @returns {String} item label text on card. 
   */
    public static getItemLabelText(problem: Problem, i18n: any): string {
        let item: any;
        let itemName: string;
        let label: string;
        switch (problem.className) {
            case Constants.simpleChangeRevision:
                item = problem.impactedItem;
                itemName = item?.name || i18n.NoItemText;
                label = i18n.ImpactedItems;
                break;
            default:
                item = problem.problemItem;
                itemName = item?.name || i18n.NoItemText;
                label = i18n.ProblemItems;
        }
        return `${label}: ${itemName}`;
    }

    /**
   * @function getLabelForItemPicker
   * @description method to get item label text.
   * @param boName {string} Object name.
   * @param problem {i18n} Localized messages objects.
   * @returns {String} item label text on create panel. 
   */
    public static getLabelForItemPicker(boName: string, i18n: any): string {
        let label: string;
        switch (boName) {
            case Constants.simpleChangeRevision:
                label = i18n.ImpactedItems;
                break;
            default:
                label = i18n.ProblemItems;
        }
        return label;
    }
}
